import axios from 'axios'
import {api} from '../helper/utils'

export const UsersModel = {
    name: "",
    email: "",
    auth: false,
}

export const login = (email, password) => {
    return new Promise(function(resolve, reject) {
        axios.post(api("users/auth"), {email, password}).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const logout = (token) => {
    return new Promise(function(resolve, reject) {
        axios.patch(api("users/logout"), {}, {
            "headers": {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.statusText)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getProfile = (token) => {
    return new Promise(function(resolve, reject) {
        axios.get(api("users/profile"), {
            "headers": {'auth-token': token}
        }).then(res => {            
            if(res.status === 200) {
                
                resolve(res.data.data[0].email)
            }else{
                reject(res.statusText)
            }
        }).catch(err => reject((err.response) ? (err.response) ? err.response.data : err : err))
    })
}

export const updateprofile = (token, id, data) => {
    return new Promise(function(resolve, reject) {
        axios.patch(api(`users/${id}`), data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const verifyEmail = (email) => {
    return new Promise(function(resolve, reject) {
        axios.get(api(`users/?email=${email}`)).then(res => {
            if(res.status === 200) {
                resolve((res.data.data.length > 0) ? false : true)
            }else{
                reject(res.statusText)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const register = (data) => {
    return new Promise(function(resolve, reject) {
        axios.post(api('users'), data).then(res => {
            if(res.status === 200) {
                resolve("Registration Success, you need to wait for your account activation, before you can login.")
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getAccounts = (token) => {
    return new Promise(function(resolve, reject) {
        axios.get(api('users/account'), {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteAccount = (id, token) => {
    return new Promise(function(resolve, reject) {
        axios.delete(api(`users/${id}`), {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const activate_user = (id, data, token) => {
    return new Promise(function(resolve, reject) {
        axios.patch(api(`users/${id}`), data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const forgotPassword = (email) => {
    return new Promise(function(resolve, reject) {
        axios.patch(api('users/forgotpassword'), {email: email}).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}