import React, { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { 
    Container, 
    Grid,
    Button, 
    Stepper, 
    Step, 
    StepLabel,
    AppBar,
    Toolbar,
    Fade,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HelpIcon from '@material-ui/icons/Help';
import Questions from '../component/questions'
import { useDispatch, useSelector } from 'react-redux'
import { next_questions, back_questions, processing_back, timeup, jump_questions, next_step, help_exam, start_answer_time, reset_answer_time, show_instruction, close_instruction, set_listeningPart, next_listening_part_b, next_listening_part_c, next_listening_part_question_state, jump_listening_part_question_state, set_exam_step_category } from '../actions/examActions'
import { show_dialog, hide_dialog } from '../actions/dialogActions'
import { calculate_section_result } from '../helper/resultmatrix'
import { showloader, hideloader } from '../actions/loaderActions'
import Tour from 'reactour'
import lang from '../helper/localize'
import ReactHowler from 'react-howler'
import { baseURL } from '../helper/utils'
import clsx from 'clsx'
import {Examtimer, ListeningController} from '../component/examsidebar';
import MiniMap from '../component/minimapno'
import isElectron from 'is-electron'
import moment from 'moment'
import {config} from '../helper/config'
import params from '../helper/params'

const useStyle = makeStyles(theme => ({
    root: {
        overflow: "hidden",
        display: 'flex',
        flexDirection: 'column',
        paddingTop:40
    },
    arabicFont: {
        fontSize: 28,
        fontWeight: "bold",
        textAlign: "right",
        fontFamily: "Scheherazade"
    },
    button: {
    marginLeft: theme.spacing(1),
    },
    questionBox: {
        padding: 15,
        border: '1px solid #2196f3',
        backgroundColor: 'white'
    },
    text: {
        padding: theme.spacing(2, 2, 0),
      },
      paper: {
        paddingBottom: 50,
      },
      list: {
        marginBottom: theme.spacing(2),
      },
      subheader: {
        backgroundColor: theme.palette.background.paper,
      },
      appBar: {
        top: 'auto',
        bottom: 0,
        backgroundColor: '#f5f5f5'
      },
      grow: {
        flexGrow: 1,
      },
      fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
      },
}));

const Exam = props => {
    const classes = useStyle()
    const examdata = JSON.parse(localStorage.getItem('examdata'))
    let auth = localStorage.getItem("auth")

    var questionsState = useSelector(state => state.questions)
    var questionPartState = useSelector(state => state.listeningPartQuestionState)
    const examIndex = useSelector(state => state.examState)
    const audioVolume = useSelector(state => state.listeningVolume) 
    const listeningPart = useSelector(state => state.listeningPart)
    const examType = useSelector(state => state.examTypeState)

    const dispatch = useDispatch()
    const history = useHistory()
    const isHelp = useSelector(state => state.examHelp)
    
    const [isListeningAudio, setListeningAudio] = useState(true)
    const [audioFile, setAudioFile] = useState(null)
    var partBIndex = useSelector(state => state.listeningPartBIndex)
    var partCIndex = useSelector(state => state.listeningPartCIndex)
    const isInstruction = useSelector(state => state.instructionPage.isopen)
    const steps = examdata.steps;
    const [partcheck, setPartCheck] = useState([])
    const [openAudioBreak, setAudioBreak] = useState(false)
    let audioChecker = null
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        if(!isElectron() && config.env === params.env.PROD) {
            history.replace('/')
        }else{
            showInstruction(examIndex, 1)
            dispatch(timeup(examdata.exam[examIndex].endtime))
            document.body.style.backgroundColor = "#003366";
        }
        // eslint-disable-next-line
    },[])

    function setExamStepCategory(qstate) {
        if(examdata.exam[examIndex].data[questionsState].category) {
            let category = (examdata.exam[examIndex].data[qstate]) ? examdata.exam[examIndex].data[qstate].category : ""
            dispatch(set_exam_step_category(category))
        }else{
            dispatch(set_exam_step_category(""))
        }
    }

    const getHelp = () => {
        dispatch(help_exam())
    }

    function selectAudio(question) {
        if(examdata.exam[0].data[question].audio_passage != null) {
            setAudioFile(baseURL(examdata.exam[0].data[question].audio_passage))
        }else{
            setAudioFile(baseURL(examdata.exam[0].data[question].audio))
        }
    }

    function showInstruction(examIndex, questions) {
        console.log({examType});
        switch(examIndex) {
            case params.examIndex.LISTENING:             
                if(examdata.exam_instructions[examIndex].length > 0) {
                    let pc = []
                    examdata.exam_instructions[examIndex].forEach((v, i) => {
                        pc.push(v.show_at)
                    })
                    setPartCheck(pc)

                    examdata.exam_instructions[examIndex].forEach((v, i) => {
                        if(v.steps === examIndex && v.show_at === questions) {
                            dispatch(show_instruction({
                                instruction: v.instruction,
                                question: v.question,
                                options: v.options,
                                answer: v.rightanswer,
                                audio: v.audio_instruction,
                                closeable: false,
                                rtl: (examType === params.examType.TOAFL) ? true : false
                            }))
                            setAudioFile(baseURL(v.audio_instruction))

                            examdata.exam_instructions[examIndex].splice(i, 1)
                            localStorage.setItem('examdata', JSON.stringify(examdata))
                        }  
                    })
                }
                break;
            default:
                if(examdata.exam_instructions[examIndex].length > 0){
                    let pc = []
                    examdata.exam_instructions[examIndex].forEach((v, i) => {
                        if(i > 0) {
                            pc.push(v.show_at)
                        }
                    })
                    setPartCheck(pc)
                    examdata.exam_instructions[examIndex].forEach((v, i) => {
                        if(v.steps === examIndex && v.show_at === questions) {
                            dispatch(show_instruction({
                                instruction: v.instruction,
                                question: v.question,
                                options: v.options,
                                answer: v.rightanswer,
                                audio: v.audio_instruction,
                                closeable: true,
                                rtl: (examType === params.examType.TOAFL) ? true : false
                            }))
                            autoCloseInstruction(dispatch);
                            examdata.exam_instructions[examIndex].splice(i, 1)
                            localStorage.setItem('examdata', JSON.stringify(examdata))
                        }  
                    })
                }
                break;
        }
    }

    function autoCloseInstruction(dispatch) {
        let autoClose = 25;
        let autocloseInstruction = setInterval(() => {
            autoClose--;
            if (autoClose === 0) {
                clearInterval(autocloseInstruction);
                dispatch(close_instruction());
            }
        }, 1000);
    }    

    const audioController = async () => {
        if(isInstruction === true) {
            dispatch(close_instruction())
            dispatch(set_listeningPart(examdata.exam[examIndex].data[questionsState].part))
            selectAudio(questionsState)   
        }else{            
            if(examdata.exam[0].data[questionsState].audio_passage != null) {
                examdata.exam[0].data[questionsState].audio_passage = null;
                localStorage.setItem('examdata', JSON.stringify(examdata))
                selectAudio(questionsState)
                dispatch(set_listeningPart(examdata.exam[examIndex].data[questionsState].part))
            }else{
                setListeningAudio(false)         
                let i = (examType === params.examType.TOAFL) ? 12 : 6   
                if (examType === params.examType.TIPA) {
                    i = 15
                }
                dispatch(reset_answer_time(i))
                let timer = setInterval(function(){
                    dispatch(start_answer_time())
                    i--
                    if(i === 0) {
                        setListeningAudio(true)
                        dispatch(reset_answer_time((examType === params.examType.TOAFL) ? 12 : 6))
                        clearInterval(timer)
                        handleNext()
                        
                        if(questionsState < examdata.exam[examIndex].data.length -1) {
                            if(partcheck.includes(questionsState+2)) {
                                showInstruction(examIndex, questionsState+2)
                            }else{
                                selectAudio(questionsState+1)
                            }
                        }
                    }
                }, 1000);
            }
        }
    }

    const checkListeningPart = () => {
        let totalpart_bgroup = examdata.exam[0].b.length;
        let totalpart_cgroup = examdata.exam[0].c.length;
        if(listeningPart === "b") {
            if(questionPartState >= examdata.exam[0].b[partBIndex].questions.length-1) {
                dispatch(jump_listening_part_question_state(0))
                dispatch(next_listening_part_b(totalpart_bgroup))
            }else{
                dispatch(next_listening_part_question_state())
            }
        }
        if(listeningPart === "c") {
            if(questionPartState >= examdata.exam[0].c[partCIndex].questions.length-1) {
                dispatch(jump_listening_part_question_state(0))
                dispatch(next_listening_part_c(totalpart_cgroup))
            }else{
                dispatch(next_listening_part_question_state())
            }
        }
    }

    const handleNext = () => {
        if (questionsState < examdata.exam[examIndex].data.length -1) {
            dispatch(next_questions())
            setExamStepCategory(questionsState+1)
            if(examIndex === params.examIndex.LISTENING) {
                dispatch(set_listeningPart(examdata.exam[examIndex].data[questionsState+1].part))
                if(listeningPart !== "a") {
                    checkListeningPart()
                }
            }else{
                showInstruction(examIndex, questionsState+2)
            }
        }else{
            if(examIndex < examdata.steps.length-1){
                if(examIndex === params.examIndex.LISTENING) {
                    setListeningAudio(false)
                    setAudioFile(null)
                    dispatch(jump_questions(1))
                    dispatch(set_listeningPart("a"))
                    calculate_section_result(examdata.exam[examIndex], examIndex)
                    dispatch(next_step())
                    dispatch(jump_questions(0))
                    let structureTime = (examType === params.examType.TOAFL) ? 30 : 25
                    dispatch(timeup(moment().add(structureTime, 'minutes').format("YYYY-MM-DD HH:mm:ss")))
                    dispatch(hide_dialog())
                    showInstruction(examIndex+1, 1)
                }else{
                    dispatch(show_dialog({
                        title: lang.attention,
                        message: lang.next_step_message,
                    }, () => {
                            calculate_section_result(examdata.exam[examIndex], examIndex)
                            dispatch(set_listeningPart("a"))
                            dispatch(next_step())
                            dispatch(jump_questions(0))
                            setExamStepCategory(questionsState+1)
                            let readingTime = (examType === params.examType.TOAFL) ? 50 : 55
                            dispatch(timeup(moment().add(readingTime, 'minutes').format("YYYY-MM-DD HH:mm:ss")))
                            dispatch(hide_dialog())
                            showInstruction(examIndex+1, 1)
                            setListeningAudio(false)
                        }
                    ))
                }
            }else{
                dispatch(show_dialog({
                    title: lang.attention,
                    message: lang.end_test_message,
                }, () => {
                    dispatch(hide_dialog())
                    dispatch(showloader(lang.processing_result))
                        setTimeout(() => {
                            dispatch(hideloader())
                            calculate_section_result(examdata.exam[examIndex], examIndex)
                            history.replace('/result')
                        },3000)
                    }
                ))
            }
        }
    };

    const handleBack = () => {
        if (questionsState > 0) {
            dispatch(processing_back())
            dispatch(back_questions())
            setExamStepCategory(questionsState-1)
            showInstruction(examIndex, ((questionsState-2) < 0) ? questionsState-2 : 0)
        } 
    };

    const checkValue = () =>{        
        let val = JSON.parse(localStorage.getItem("answer"))
        if(examIndex === params.examIndex.READING) {
            return (val === null) ? [[]] : val
        }else{
            return (val === null) ? "" : val[questionsState]
        }
    }

    const checkValueListening = () => {
        let val = JSON.parse(localStorage.getItem("listening_answer"))
        return (val === null) ? [[]] : val
    }

    const toursteps = [{
        selector: '.first-step',
        content: lang.help_step_one
    },{
        selector: '.second-step',
        content: lang.help_step_two
    },{
        selector: '.third-step',
        content: lang.help_step_three
    },{
        selector: '.four-step',
        content: lang.help_step_four
    },{
        selector: '.five-step',
        content: lang.help_step_five
    },{
        selector: '.six-step',
        content: lang.help_step_six
    }]

    const examStepArabic = (step) => {
        switch (step) {
            case "LISTENING":
                return "فهم المسموع";
            case "STRUCTURE":
                return "فهم التراكيب والعبارات";
            case "READING":
                return "فهم المقروء";
            default:
                break;
        }
    }

    const handleReloadAudio = () => {
        setAudioBreak(false)
        dispatch(showloader("Loading Audio..."))
        setTimeout(() => {
            console.log("REFRESH AUDIO");
            setAudioFile("reload.mp3")
            selectAudio(questionsState)
            forceUpdate()
        }, 3000);
    }
   
    return <Fade in={true} timeout={500}><div>
        {(auth === "false") ? 
            <Redirect to="/exam-auth"/> 
        :     
            <div>
                {(audioFile !== null && examIndex === params.examIndex.LISTENING) ? <ReactHowler 
                    key={questionsState}
                    src={audioFile} 
                    volume={audioVolume/10}
                    playing={isListeningAudio}
                    onEnd={audioController}
                    onLoadError={() => {
                        dispatch(hideloader())
                        setAudioBreak(true)
                    }}
                    onLoad={() => {
                        dispatch(hideloader())
                        clearInterval(audioChecker)
                    }}

                /> : null}
                <AppBar position="sticky" color="inherit" elevation={10} >
                    <Grid 
                        item 
                        xs={12} 
                        sm={12} 
                        lg={12} 
                        style={{marginTop:0, marginBottom:0, height: '100px'}} 
                        dir={(examType === params.examType.TOAFL) ? "rtl" : "ltr"}>

                        <Stepper 
                            activeStep={examIndex} 
                            className="first-step">

                            {steps.map(label => (
                            <Step key={label}>
                                {(examType === params.examType.TOAFL) ? 
                                    <StepLabel>
                                        <span className={classes.arabicFont}>
                                            &nbsp;{examStepArabic(label)}
                                        </span>
                                    </StepLabel> 
                                    : <StepLabel>
                                        &nbsp;{label}
                                    </StepLabel>}
                            </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </AppBar>
                
                <Container maxWidth="lg" className={classes.root}>
                    <Grid container>

                        <Grid item xs={12} sm={12} lg={12}>

                            <Grid container>
                                <Grid 
                                    item 
                                    xs={12} 
                                    sm={12} 
                                    align={(examType === params.examType.TOAFL) ? "right": "left"} 
                                    className={clsx("four-step", classes.questionBox)} 
                                    style={{marginBottom:'200px'}}>
                                    {/* {questionsState === 0 && <Alert severity="info">
                                        <AlertTitle>Instruction</AlertTitle>
                                    This is an info alert — check it out!
                                    </Alert>} */}
                                    <br/>
                                    <Questions 
                                        steps={steps[examIndex]} 
                                        questions={examdata.exam[examIndex].data[questionsState]} 
                                        value={(listeningPart === "b" || listeningPart === "c") ? 
                                            checkValueListening 
                                            : checkValue} no={questionsState} 
                                        examdata={examdata.exam[examIndex].data}
                                        listening_part={(listeningPart === "b") ? 
                                            examdata.exam[examIndex].b[partBIndex] 
                                            : (listeningPart === "c") ? 
                                                examdata.exam[examIndex].c[partCIndex] 
                                                : []}
                                        listening_index={(listeningPart === "b") ? 
                                            0 
                                            : (listeningPart === "c") ? 
                                                1 
                                                : 0}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>               
                    </Grid>

                </Container>
                <AppBar position="fixed" color="inherit" elevation={0} className={classes.appBar}>
                    <Toolbar>
                        <Container maxWidth="lg" align="right">
                            {(examIndex === params.examIndex.LISTENING) ? 
                                <ListeningController examdata={examdata.exam[examIndex].data}/> 
                                : <Grid container>
                                <Grid item xs={2} sm={2} align="left" className="second-step">
                                    <Examtimer/>
                                </Grid>
                                <Grid item xs={8} sm={8} align="left" style={{padding:'5px'}} className="third-step">
                                    <fieldset style={{padding:'0px'}}>
                                        <legend>
                                            {(examIndex === params.examIndex.READING) ? lang.passage_no : lang.all_questions}
                                        </legend>
                                        <MiniMap questions={examdata.exam[examIndex].data} />
                                    </fieldset>
                                </Grid>
                                <Grid 
                                    item 
                                    xs={2} 
                                    sm={2} 
                                    align="right" 
                                    style={{padding:'5px'}}  
                                    className="five-step" 
                                    dir={(examType === params.examType.TOAFL) ? 
                                        "rtl" 
                                        : "ltr"}>

                                    <Button 
                                        startIcon={<HelpIcon/>} 
                                        onClick={getHelp} 
                                        className={clsx(classes.button, "six-step")} >
                                            &nbsp;{lang.help}
                                    </Button>

                                    <br/>
                                    <br/>
                                    
                                    <Button
                                        onClick={handleBack}
                                        className={classes.button}    
                                        variant="outlined"
                                        color="default"
                                        startIcon={(examType === params.examType.TOAFL) ? 
                                            <ArrowForwardIcon /> 
                                            : <ArrowBackIcon />}>
                                                &nbsp;
                                    </Button>
                                    <Button
                                        onClick={handleNext}
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        endIcon={(examType === params.examType.TOAFL) ? 
                                            <ArrowBackIcon /> 
                                            : <ArrowForwardIcon />}>
                                                &nbsp;
                                    </Button>
                                </Grid>
                            </Grid>}
                        </Container>
                    </Toolbar>
                </AppBar>
            </div>
        }
        <Tour
            steps={toursteps}
            isOpen={isHelp}
            onRequestClose={() => dispatch(help_exam())}
        />
        <Dialog
            open={openAudioBreak}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" children="paragraph">{lang.attention}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                {lang.reload_audio_message}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleReloadAudio} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </div>
    </Fade>
}

export default Exam