import React, {useState} from 'react'
import Countdown, {zeroPad} from 'react-countdown';
import { Redirect } from 'react-router-dom';
import { calculate_section_result } from '../helper/resultmatrix'
import { useDispatch, useSelector } from 'react-redux'
import { next_step, jump_questions, timeup, audio_volume } from '../actions/examActions'
import { showloader, hideloader } from '../actions/loaderActions'
import lang from '../helper/localize'
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import moment from 'moment'
import params from '../helper/params'

export const Examtimer = props => {

    const examIndex = useSelector(state => state.examState)
    const dispatch = useDispatch()
    const timer = useSelector(state => state.timeState)
    const [allsteps, setSteps] = useState(0)
    const examdata = JSON.parse(localStorage.getItem("examdata"))
    const examType = useSelector(state => state.examTypeState)

    const renderer = ({ hours, minutes, seconds, completed  }) => {

        if(allsteps === examdata.steps.length) {

            return <Redirect to="/result"/>
        }

        return <h1 align="left" 
            style={{fontSize:42, color:'red'}}>
                {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </h1>;
    };

    const handleOnComplete = props => {
        if (examIndex < examdata.steps.length-1) {
            calculate_section_result(examdata.exam[examIndex], examIndex)
            dispatch(next_step())
            dispatch(jump_questions(0))
            let readingTime = (examType === params.examType.TOAFL) ? 50 : 55
            dispatch(timeup(moment().add(readingTime, 'minutes').format("YYYY-MM-DD HH:mm:ss")))
        } else {
            dispatch(showloader("Processing Result..."))
            setTimeout(() => {
                dispatch(hideloader())
                calculate_section_result(examdata.exam[examIndex], examIndex)
                setSteps(examdata.steps.length)
            }, 3000)
        }
    }

    return <Countdown date={timer}
        key={examIndex}
        autoStart={true}
        zeroPadTime={2}
        renderer={renderer}
        onComplete={handleOnComplete}
    ></Countdown>
}

export const ListeningController = props => {

    const dispatch = useDispatch()
    const questionsState = useSelector(state => state.questions)
    const answertime = useSelector(state => state.listeningAnswer)
    const audioVolume = useSelector(state => state.listeningVolume)

    const volumeController = (event, newValue) => {
        dispatch(audio_volume(newValue))
    }

    const answerTimer = (time) => {
        if(time < 10) {
            return `0${time}`
        }else{
            return time
        }
    }

    return <Grid container style={{padding:16,fontWeight:"bold",fontSize:20}} spacing={1}>
        <Grid item xs={6} sm={6} align="left">
            <fieldset style={{padding:'0px 16px',margin:0,border:'1px solid #e0e0e0'}}>
                <legend style={{fontSize:12}}>{lang.details}</legend>
                <Grid container>
                    <Grid item xs={4} sm={4} align="left">
                        <p>{lang.part} {(props.examdata[questionsState].part).toString().toUpperCase()}</p>
                    </Grid>
                    <Grid item xs={4} sm={4} align="center">
                        <p style={{fontSize:14}}>
                            {lang.answer_time}: <br/> 
                            <span style={{color:"red",fontSize:24}}>
                                00:00:{answerTimer(answertime)}
                            </span> 
                        </p>
                    </Grid>
                    <Grid item xs={4} sm={4} align="right">
                        <p style={{fontSize:14}}> 
                            {lang.questions} <br/>
                            <span style={{color:"blue",fontSize:24}}>
                                {questionsState+1}/{props.examdata.length}
                            </span>
                        </p>
                    </Grid>
                </Grid>
            </fieldset>
        </Grid>
        <Grid item xs={6} sm={6} align="left">
            <fieldset style={{padding:16,margin:0,border:'1px solid #e0e0e0'}}>
                <legend style={{fontSize:12}}>Volume</legend>
                <Grid container spacing={2}>
                    <Grid item style={{marginTop:'10px'}}>
                        <VolumeDown />
                    </Grid>
                    <Grid item xs>
                        <Slider value={audioVolume} 
                            onChange={volumeController} 
                            aria-labelledby="continuous-slider" 
                            min={1} 
                            max={10} 
                            steps={10} 
                        />
                    </Grid>
                    <Grid item style={{marginTop:'10px'}}>
                        <VolumeUp />
                    </Grid>
                </Grid>
            </fieldset>
        </Grid>
    </Grid>
}