// set env to dev or prod
var env = "prod"
var apiroot = "/api/v1/"
var apihost = ""
var audio_base_url = ""

switch (env) {
    case "dev":
        apihost = "http://localhost:4000"
        audio_base_url = "https://cbt-ppb-uin.s3-ap-southeast-1.amazonaws.com/"
        break;
    case "prod":
        apihost = "https://tesbahasa.uinjkt.ac.id"
        audio_base_url = "https://cbt-ppb-uin.s3-ap-southeast-1.amazonaws.com/"
        break;
    default:
        audio_base_url = "https://cbt-ppb-uin.s3-ap-southeast-1.amazonaws.com/"
        break;
}

export const config = {
    apiroot,
    audio_base_url,
    env,
    apihost
}