import React, {useState, useEffect, useRef } from 'react'
import { Container, Grid, Paper, makeStyles, Divider, FormControl, InputLabel, Select, MenuItem, Button, DialogContent, Dialog, DialogTitle, useTheme, List, ListItem, ListItemAvatar, Avatar, ListItemText, Input, Tab, Tabs } from '@material-ui/core'
import '../main.scss'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {getExamSchedule, addExamSchedule, editExamSchedule, deleteExamSchedule, getExamArchiveXLS} from '../model/exammodel'
import { showloader, hideloader } from '../actions/loaderActions'
import { show_alert } from '../actions/alertActions'
import {useDispatch} from 'react-redux'
import { show_dialog, hide_dialog } from '../actions/dialogActions'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ParticipantList from '../component/participant'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { getAllParticipant } from '../model/participantmodel'
import lang from '../helper/localize'
import { testAlias } from '../helper/utils'

import {getAllVenue, getAllExamType} from '../model/settingmodel'
import Certificate from '../component/certificateManagement'
import ParticipantDatabase from '../component/participantDatabase'

const useStyle = makeStyles(theme => ({
    calendarcontainer: {
        padding: 16,
        marginTop: theme.spacing(2)
    },
    formControl: {
        marginBottom:10
    }
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}

const ExamManagement = props => {
    const calendarComponentRef = useRef()
    const classes = useStyle()
    const dispatch = useDispatch()
    const theme = useTheme()
    const token = localStorage.getItem('token')
    const [exams, setExams] = useState([])
    const [tabIndex, setTabIndex] = useState(0)
    const [testType, setTestType] = useState("")
    const [selectedDate, setSelectedDate] = useState(null)
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [addEventDialog, setAddEventDialog] = useState(false)
    const [dialogExam, setDialogExam] = useState({
        title: "",
        data: {},
        extend: {},
        venue: ""
    })
    const [isEdit, setIsEdit] = useState(false)
    const [openParticipantList, setOpenParticipantList] = useState(false)
    const [venues, setVenues] = useState([]);
    const [personNameList, setPersonNameList] = useState([]);
    const [examTypeList, setExamTypeList] = useState([])
    const [participantData, setParticipantData] = useState({
        columns: [
          { title: 'Participant', field: 'name' },
          { title: 'Token', field: 'token' },
          { title: 'Status', field: 'status' },
          { title: 'Register', field: 'created_at' },
          { title: 'Exam Status', field: 'examlimit' },
        ],
        data: []
    });
    const [calendarStartDate, setCalendarStartDate] = useState(
        moment().startOf('month').format('YYYY-MM-DD')
    )

    const [calendarEndDate, setCalendarEndDate] = useState(
        moment().endOf('month').format('YYYY-MM-DD')
    )

    const [calendarCurrentMonth, setCalendarCurrentMonth] = useState(
        calendarStartDate
    )

    var user = JSON.parse(localStorage.getItem('auth'))

    const handleOpenParticipantList = () => {
        refreshTable(dialogExam.data.id)
    }
    
    const handleDateChange = e => {
        let dt = moment(e).format("YYYY-MM-DD")
        setSelectedDate(dt);
    }

    const onChangeStartTime = e => {
        // let dt = moment(e).format("HH:mm:ss")
        setStartTime(e)
    }
    const onChangeEndTime = e => {
        // let dt = moment(e).format("HH:mm:ss")
        setEndTime(e)
    }

    const handleCloseExamDialog = () => {
        setDialogOpen(!dialogOpen)
    }

    const hanldeCloseEventDialog = () => {
        setAddEventDialog(!addEventDialog)
    }

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue)
        setOpenParticipantList(false)
    };

    const handleDeleteExam = () => {
        setDialogOpen(false)
        dispatch(show_dialog({
            title: lang.attention,
            message: lang.deleteconfirm
        }, () => {
            dispatch(hide_dialog())
            dispatch(showloader(lang.processing_delete))
            let token = localStorage.getItem('token')
            deleteExamSchedule(dialogExam.data.id, token).then(res => {
                let ne = [...exams]
        
                let index = ne.findIndex(e => e.id === dialogExam.data.id)
        
                if(index !== -1){
                    ne.splice(index, 1)
                    setExams(ne)
                }
                dispatch(show_alert({
                    status: "success",
                    message: res.message
                }))
            }).catch(err => {
                dispatch(show_alert({
                    status: "error",
                    message: err.message
                }))
            }).finally(() => {
                dispatch(hideloader())
            })
        }))
    }

    const handleEditExam = () => {
        setIsEdit(true)
        setDialogOpen(false)
        setAddEventDialog(true)
        setSelectedDate(dialogExam.data.start)
        setStartTime(dialogExam.extend.examstart)
        setEndTime(dialogExam.extend.examend)
        setTestType(dialogExam.extend.examtypeid)
        let venueid = []
        dialogExam.extend.venues.forEach((v, i) => {
            venueid.push(v._id)
        })
        setVenues(venueid)
    }

    const onCreateExam = data => {
        
        let token = localStorage.getItem('token')
                
        if(selectedDate != null && startTime != null && endTime != null) {
            let nselectedDate = moment(selectedDate).format("YYYY-MM-DD")
            let nstartTime = moment(startTime).format("HH:mm:ss")
            let nendTime = moment(endTime).format("HH:mm:ss")

            if(venues.length === 0) {
                dispatch(show_alert({
                    status: 'error',
                    message: lang.venue_validation
                }))
            }else{
                dispatch(showloader(lang.processing_new_exam))
                if(isEdit) {
                    editExamSchedule(dialogExam.data.id, {
                        examtype: testType,
                        starttime: `${nselectedDate} ${nstartTime}`,
                        endtime: `${nselectedDate} ${nendTime}`,
                        venue: venues
                    }, token).then(res => {
                        dispatch(show_alert({
                            status: "success",
                            message: res.message
                        }))
                        getSchedule({
                            startDate: calendarStartDate,
                            endDate: calendarEndDate
                        })
                        setVenues([])
                        setTestType("")
                    }).catch(err => {
                        dispatch(show_alert({
                            status: "error",
                            message: err.message
                        }))            
                    }).finally(() => {
                        setIsEdit(false)
                        setSelectedDate(null)
                        setStartTime(null)
                        setEndTime(null)
                        dispatch(hideloader())
                        setAddEventDialog(false)
                    })
                }else{
                    setAddEventDialog(false)
                    addExamSchedule({
                        examtype: testType,
                        starttime: `${nselectedDate} ${nstartTime}`,
                        endtime: `${nselectedDate} ${nendTime}`,
                        venue: venues
                    }, token).then(res => {
                        console.log(res);
                        
                        dispatch(show_alert({
                            status: "success",
                            message: res.message
                        }))  
                        getSchedule({
                            startDate: calendarStartDate,
                            endDate: calendarEndDate
                        })
                        setVenues([])
                        setTestType("")                
                    }).catch(err => {
                        dispatch(show_alert({
                            status: "error",
                            message: err.message
                        }))            
                    }).finally(() => {
                        setSelectedDate(null)
                        setStartTime(null)
                        setEndTime(null)
                        dispatch(hideloader())
                    })
                }
            }

        }else{
            dispatch(show_alert({
                status: 'error',
                message: lang.exam_time_validation
            }))
        }
    }

    const onChangeTestType = e => {        
        setTestType(e.target.value)
    }

    const handleVenueChange = event => {
        setVenues(event.target.value);
    };

    const refreshTable = (examid) => {
        getAllParticipant(token, examid).then(res => {
          
          if(res.data.length !== 0) {
            res.data.forEach((dt, i) => {
              res.data[i].created_at = moment(dt.created_at).fromNow()
              res.data[i].examlimit = (res.data[i].examlimit === 0) ? "NOT START" : (res.data[i].examlimit === 1 && res.data[i].result.length === 0 && !res.data[i].totalresult) ? "LOGIN" : (res.data[i].totalresult) ? "DONE" : "START"
              if(res.data[i].exam !== null) {
                res.data[i].exam.title = `Exam | ${moment(dt.exam.starttime).format("YYYY-MM-DD HH:mm")}`
              }else{
                res.data[i].exam = {'title': 'Exam not exist'}
              }
            })
          }
          
          setParticipantData({
            columns:  [
                { title: 'Participant', field: 'name' },
                { title: 'Token', field: 'token' },
                { title: 'Status', field: 'status' },
                { title: 'Register', field: 'created_at' },
                { title: 'Exam Status', field: 'examlimit' },
              ],
            data: res.data
          })
        }).catch(err => {
          dispatch(show_alert({
            status: "error",
            message: err.message
          }))
        }).finally(() => {
            setOpenParticipantList(true)
            setDialogOpen(false)            
        })
    }

    function getSchedule({startDate, endDate}) {
        dispatch(showloader(lang.loading))
        getExamSchedule({startDate, endDate}).then(res => {
            if(res.data.length !== 0) {
                let nexam = []
                res.data.forEach(exam => {
                    let tagColor = (exam.examtype.name === "TOAFL") ? "#009688" : (exam.examtype.name === "TOEFL") ? "#2196f3" : "#263238";
                    nexam.push({id: exam._id, examtype: exam.examtype.name, examtypeid: exam.examtype._id, venues: exam.venue, title: `${(exam.examtype.name) ? testAlias(exam.examtype.name) : 'Exam'} | ${moment(exam.starttime).format("HH:mm:ss")}`, date: moment(exam.starttime).format("YYYY-MM-DD"), examstart: moment(exam.starttime), examend: moment(exam.endtime), backgroundColor: tagColor, textColor: '#fff', totalParticipant: exam.participant.length})
                })

                setExams(nexam)
            }
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: JSON.stringify(err.message)
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    const getVenue = () => {
        getAllVenue(token).then(data => {            
            setPersonNameList(data)
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        })
    }

    const getExamType = () => {
        getAllExamType().then(data => {
            setExamTypeList(data)
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        })
    }

    const totalQuota = (venue) => {
        let quota = 0
        venue.forEach((v, i) => {
            quota += v.quota
        })

        return quota
    }

    const setCalendarDate = ({curMonth, startDate, endDate}) => {
        setCalendarCurrentMonth(curMonth)
        setCalendarStartDate(
            startDate
        )
        setCalendarEndDate(
            endDate
        )
        let calendarApi = calendarComponentRef.current.getApi();
        calendarApi.gotoDate(curMonth)
        getSchedule({
            startDate,
            endDate
        })
    }

    const handleArchive = () => {
        dispatch(showloader(lang.loading))
        getExamArchiveXLS(token, {
            startDate: calendarStartDate,
            endDate: calendarEndDate
        }).then(res => {
            dispatch(show_alert({
                status: 'success',
                message: JSON.stringify(res.message)
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: JSON.stringify(err.message)
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    const handleToday = () => {
        let curMonth = moment().format('YYYY-MM-DD')
        let startDate = moment(curMonth).startOf('month').format('YYYY-MM-DD')
        let endDate = moment(curMonth).endOf('month').format('YYYY-MM-DD')

        setCalendarDate({curMonth, startDate, endDate})
    }

    const handlePrev = () => {
        let curMonth = moment(calendarCurrentMonth).subtract(1, 'month').format('YYYY-MM-DD')
        let startDate = moment(curMonth).startOf('month').format('YYYY-MM-DD')
        let endDate = moment(curMonth).endOf('month').format('YYYY-MM-DD')

        setCalendarDate({curMonth, startDate, endDate})
    }

    const handleNext = () => {
        let curMonth = moment(calendarCurrentMonth).add(1, 'month').format('YYYY-MM-DD')
        let startDate = moment(curMonth).startOf('month').format('YYYY-MM-DD')
        let endDate = moment(curMonth).endOf('month').format('YYYY-MM-DD')

        setCalendarDate({curMonth, startDate, endDate})
    }

    useEffect(() => {
        getSchedule({
            startDate: calendarStartDate,
            endDate: calendarEndDate
        })
        getVenue()
        getExamType()
        // eslint-disable-next-line
    }, [])

    return <div>
        <Container maxWidth="lg">
            <Grid container style={{marginTop:20}} spacing={2}>
                <Grid item lg={12} sm={12} xs={12}>
                    <Paper>
                        <Tabs
                            value={tabIndex}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChange}
                            aria-label="menu tab">
                                {/* <Tab label="General" /> */}
                                {(user && (user.role === "ADMIN" || user.role === "MANAGER" || user.role === "INVIGILATOR")) && <Tab label="Exam Schedule" />}
                                {(user && (user.role === "ADMIN" || user.role === "MANAGER")) && <Tab label="Participant Database" />}
                                {(user && (user.role === "ADMIN" || user.role === "MANAGER")) && <Tab label="Certificate" />}
                        </Tabs>
                    </Paper>
                    <Divider />
                    {(tabIndex === 0) && <Paper className={classes.calendarcontainer}>
                        <FullCalendar
                            ref={calendarComponentRef}
                            customButtons={
                                {
                                    archive: {
                                        text: "Archive",
                                        click: () => handleArchive()
                                    },
                                    ctoday: {
                                        text: 'Today',
                                        click: () => handleToday()
                                    },
                                    cnext: {
                                        text: ">",
                                        click: () => handleNext()
                                    },
                                    cprev: {
                                        text: "<",
                                        click: () => handlePrev()
                                    }
                                }
                            }
                            header={{
                                left: 'title',
                                right: 'ctoday cprev,cnext archive'
                            }}
                            dateClick={(info) => {
                                setAddEventDialog(true)
                                setSelectedDate(info.date)
                            }}
                            selectable={true}
                            defaultView="dayGridMonth" 
                            plugins={[ dayGridPlugin, interactionPlugin ]} 
                            contentHeight={510}
                            editable={true}
                            events={exams}
                            eventClick={(info) => {
                                setDialogExam({
                                    title: info.event.title,
                                    data: info.event,
                                    extend: info.event.extendedProps,
                                    venue: "TEST"
                                })
                                setDialogOpen(true)     
                                setOpenParticipantList(false)                          
                            }}
                            defaultDate={calendarCurrentMonth}
                        />
                    </Paper>}
                    
                    {(tabIndex === 1) && <Paper className={classes.calendarcontainer} style={{padding: 0}}>
                        <ParticipantDatabase/>
                    </Paper>}

                    {(tabIndex === 2) && <Paper className={classes.calendarcontainer}>
                        <Certificate/>
                    </Paper>}
                </Grid>
            </Grid>
        </Container>
        { (openParticipantList) && <ParticipantList title={`${testAlias(dialogExam.extend.examtype)} | ${moment(dialogExam.data.start).format("dddd, Do MMM YYYY")} ${moment(dialogExam.extend.examstart).format("HH:mm")} - ${moment(dialogExam.extend.examend).format("HH:mm")}`} onClose={() => setOpenParticipantList(false)} participantData={participantData} examid={dialogExam.data.id} />}
        <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={dialogOpen}
            onClose={handleCloseExamDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{lang.exam} {(dialogExam.extend) ? testAlias(dialogExam.extend.examtype) : ""}</DialogTitle>
            <DialogContent>
                <table>
                    <tbody>
                        <tr>
                            <td>START</td>
                            <td>: {(dialogExam.data) && moment(dialogExam.data.start).format("DD-MM-YYYY")}</td>
                        </tr>
                        <tr>
                            <td>TIME</td>
                            <td>: {(dialogExam.data) && moment(dialogExam.extend.examstart).format("HH:mm")} - {(dialogExam.data) && moment(dialogExam.extend.examend).format("HH:mm")} </td>
                        </tr>
                        <tr>
                            <td>Venue</td>
                            <td>
                                <ul>
                                    {
                                        (dialogExam.extend.venues) && dialogExam.extend.venues.map((v,i) => <li key={i}>{v.name} - {v.quota}</li>)
                                    }
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Quota</td>
                            <td>
                                {(dialogExam.extend.totalParticipant) && dialogExam.extend.totalParticipant}/{(dialogExam.extend.venues) && totalQuota(dialogExam.extend.venues)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </DialogContent>
            <List>
                {(user && (user.role === "ADMIN" || user.role === "MANAGER")) && <div>
                    <ListItem button onClick={handleDeleteExam}>
                        <ListItemAvatar>
                            <Avatar>
                                <DeleteIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText  primary={lang.delete_exam} />
                    </ListItem>
                    <ListItem button onClick={handleEditExam}>
                        <ListItemAvatar>
                            <Avatar>
                                <EditIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={lang.edit_exam} />
                    </ListItem>
                </div>}
                <ListItem button onClick={handleOpenParticipantList}>
                    <ListItemAvatar>
                        <Avatar>
                            <SupervisedUserCircleIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={lang.participant_list} />
                </ListItem>
            </List>
        </Dialog>
        <Dialog
            fullWidth={true}
            maxWidth="xs"
            open={addEventDialog}
            onClose={hanldeCloseEventDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{lang.exam}</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="selectexamtypelabel">EXAM</InputLabel>
                        <Select
                            defaultValue=""
                            value={testType}
                            onChange={onChangeTestType}
                            labelId="selectexamtypelabel"
                            id="selectexamtype"
                            input={<Input />}
                            MenuProps={MenuProps}
                            >
                                {examTypeList.map((v, i) => (
                                    <MenuItem key={i} value={v._id}>{testAlias(v.name)}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disabled={false}
                                value={selectedDate}
                                onChange={handleDateChange}
                                format="dd/MM/yyyy"
                                id="date-picker-inline-test-date"
                                label="Test date"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                name="testdate"
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                                
                            <KeyboardTimePicker
                                value={startTime}
                                onChange={onChangeStartTime}
                                format="HH:mm:ss"
                                id="date-picker-inline-start-time"
                                label="Start time"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                name="starttime"
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                                value={endTime}
                                onChange={onChangeEndTime}
                                format="HH:mm:ss"
                                id="date-picker-inline-end-time"
                                label="End time"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                name="endtime"
                            />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="demo-mutiple-name-label">Venue</InputLabel>
                        <Select
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"
                        multiple
                        value={venues}
                        onChange={handleVenueChange}
                        input={<Input />}
                        MenuProps={MenuProps}
                        >
                        {personNameList.map((v, i) => (
                            <MenuItem key={i} value={v._id} style={getStyles(v.name, venues, theme)}>
                            {v.name}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <Button variant="contained" fullWidth color="primary" type="submit" onClick={onCreateExam}>{(isEdit) ? lang.edit : lang.add}</Button>
                </DialogContent>
        </Dialog>
    </div>
}

export default ExamManagement