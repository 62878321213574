import ExamManagement from './backend/exam_management'
import QuestionBank from './backend/question_bank'
import AccountManagement from './backend/account_management'
import MyProfile from './backend/myprofile'
import Setting from './backend/settings'
import RegisterManagement from './backend/register_management'

const routes = [
    {
        path: '/console',
        component: ExamManagement,
        menu: 'EXAM'
    },
    {
        path: '/console/registration',
        component: RegisterManagement,
        menu: 'REGISTRATION'
    },
    {
        path: '/console/questionbank',
        component: QuestionBank,
        menu: 'QUESTION'
    },
    {
        path: '/console/accounts',
        component: AccountManagement,
        menu: 'ACCOUNT'
    },
    {
        path: '/console/myprofile',
        component: MyProfile,
        menu: 'MYPROFILE'
    },    
    {
        path: '/console/settings',
        component: Setting,
        menu: 'SETTING'
    }
]

export default routes