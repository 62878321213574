import React, {useEffect, useState, forwardRef} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Divider, Tab, Tabs } from '@material-ui/core'
import { show_alert } from '../actions/alertActions'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MaterialTable from 'material-table';

import { getAllVenue, getAllExamType,
         addVenue, editVenue, deleteVenue, editExamType } from '../model/settingmodel'
import { testAlias } from '../helper/utils'

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      '& > *': {
        marginTop: theme.spacing(3),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(17),
      height: theme.spacing(17),
    },
    container: {
        paddingTop: theme.spacing(2),
    },
    input: {
        marginBottom: 10
    }
  }));

const Settings = props => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const [tabIndex, setTabIndex] = useState(0)
    const [isExamTableLoad, setIsExamTableLoad] = useState(false)
    const [isVenueTableLoad, setIsVenueTableLoad] = useState(false)

    const [columnExam, setColumnExam] = useState({
        columns: [
          { title: 'Name', field: 'name', editable: 'never' },
          { title: 'Steps', field: 'steps', editable: 'never' },
          { title: 'Time (m)', field: 'times' },
        ],
        data: []
    });
    const [columnVenue, setColumnVenue] = useState({
        columns: [
          { title: 'Name', field: 'name' },
          { title: 'Code', field: 'code' },
          { title: 'Quota', field: 'quota' },
          { title: 'Time', field: 'updated_at', editable: 'never' },
        ],
        data: []
    });

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const refreshExamTable = () =>{
        setIsExamTableLoad(true)
        getAllExamType(token).then(data => {
            data.forEach((dt, i) => {
                let ndt = moment(dt.updated_at).fromNow()
                data[i].updated_at = ndt
                if(data[i].name === "TOEFL") {
                    data[i].name = testAlias(data[i].name)
                }
            })

            setColumnExam({
                columns: columnExam.columns,
                data
            })
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            setIsExamTableLoad(false)
        })
    }

    const refreshVenueTable = () => {
        setIsVenueTableLoad(true)
        getAllVenue(token).then(data => {            
            data.forEach((dt, i) => {
                let ndt = moment(dt.updated_at).fromNow()
                data[i].updated_at = ndt
            })

            setColumnVenue({
                columns: columnVenue.columns,
                data
            })
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            setIsVenueTableLoad(false)
        })
    }

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };

    useEffect(() => {
        refreshExamTable()
        refreshVenueTable()
        // eslint-disable-next-line
    }, [])

    return <div className={classes.root}>
        <Container maxWidth="lg">
            <Grid container>
                <Grid item lg={12} sm={12} xs={12}>
                    <Paper>
                        <Tabs
                            value={tabIndex}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChange}
                            aria-label="menu tab">
                                {/* <Tab label="General" /> */}
                                <Tab label="Exam" />
                                <Tab label="Venue" />
                        </Tabs>
                    </Paper>
                    
                    <Divider/>
                    <div className={classes.container}>
                        {/* {(tabIndex === 0) && <div>
                            <Paper style={{padding:16}}>
                                <h1>General</h1>
                            </Paper>                        
                        </div>} */}

                        {(tabIndex === 0) && <div>
                            <MaterialTable
                                icons={tableIcons}
                                title="All Exam"
                                columns={columnExam.columns}
                                data={columnExam.data}
                                isLoading={isExamTableLoad}
                                options={{
                                    header: true,
                                    actionsColumnIndex:3
                                }}
                                editable={{
                                    onRowUpdate: (newData, oldData) => 
                                        new Promise(function(resolve, reject) {
                                            let ndata = newData.times.split(',')
                                            if(ndata.length !== 3) {
                                                reject()
                                            }else{
                                                editExamType(token, oldData._id, newData).then(data => {
                                                
                                                    refreshExamTable()
                                                    resolve()
                                                }).catch(err => {
                                                    
                                                    dispatch(show_alert({
                                                        status: 'error',
                                                        message: err.message
                                                    }))
                                                    reject()
                                                })
                                            }
                                    })
                                }}
                                />
                        </div>}

                        {(tabIndex === 1) && <div>
                            <MaterialTable
                                icons={tableIcons}
                                title="All Venue"
                                columns={columnVenue.columns}
                                data={columnVenue.data}
                                isLoading={isVenueTableLoad}
                                options={{
                                    header: true,
                                    actionsColumnIndex:4
                                }}
                                editable={{
                                    onRowAdd: newData => 
                                        new Promise(function(resolve, reject) {                                           
                                            addVenue(token, newData).then(data => {
                                                refreshVenueTable()
                                                resolve()
                                            }).catch(err => {
                                                reject()
                                                dispatch(show_alert({
                                                    status: 'error',
                                                    message: err.message
                                                }))
                                            })  
                                        })
                                    ,
                                    onRowUpdate: (newData, oldData) => 
                                        new Promise(function(resolve, reject) {
                                            editVenue(token, oldData._id, newData).then(data => {
                                                refreshVenueTable()
                                                resolve()
                                            }).catch(err => {
                                                
                                                dispatch(show_alert({
                                                    status: 'error',
                                                    message: err.message
                                                }))
                                                reject()
                                            })
                                        })
                                    ,
                                    onRowDelete: oldData =>
                                        new Promise(function(resolve, reject) {
                                            deleteVenue(token, oldData._id).then(data => {
                                                refreshVenueTable()
                                                resolve()
                                            }).catch(err => {
                                                dispatch(show_alert({
                                                    status: 'error',
                                                    message: err.message
                                                }))
                                                reject()
                                            })
                                        })
                                }}
                                />
                        </div>}
                    </div>
                </Grid>
            </Grid>
        </Container>
    </div>
}

export default Settings