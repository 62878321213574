import React, {
    useState,
    forwardRef,
    useEffect
} from 'react'
import {
    Container,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select
} from '@material-ui/core'
import params from '../../helper/params'
import {
    testAlias
} from '../../helper/utils'
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import renderHTML from 'react-render-html';
import {
    GetInstruction
} from '../../model/instructionmodel'
import {
    useDispatch
} from 'react-redux'
import {
    show_alert
} from '../../actions/alertActions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    formControl: {
        marginBottom:10
    },
    radio:{
        '& .MuiFormControl-root': {
            width: '100%',
            marginBottom:10
        }
    },
}));

const QuestionInstruction = () => {
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };
    
    const classes = useStyles();
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    const [testType, setTestType] = useState(params.examType.ETIC)
    const [testSection, setTestSection] = useState(params.examStep.LISTENING)
    const [isTableLoad, setIsTableLoad] = useState(false)
    const [column, setColumn] = useState({
        columns: [
          { title: 'Steps', field: 'steps' },
          { title: 'Part', field: 'part' },
          { title: 'Show at', field: 'show_at' },
          { title: 'Updated At', field: 'updated_at' },
        ],
        data: []
    });

    const onChangeTestType = e => {
        let _testType = e.target.value
        setTestType(_testType)
        refreshTable(_testType, testSection)
    }

    const onChangeTestSection = e => {        
        let _testSection = e.target.value
        setTestSection(_testSection)
        refreshTable(testType, _testSection)
    }

    const refreshTable = (_testType, _testSection) => {
        setIsTableLoad(true)
        GetInstruction(token, {
            examType: _testType,
            examStep: _testSection
        }).then(res => {
            setColumn({
                columns: [
                    { title: 'Part', field: 'part' },
                    { title: 'Show at', field: 'show_at' },
                ],
                data: res.data
            })
        }).catch(err => {
            dispatch(show_alert({
                status: "error",
                message: err.message
            }))
        }).finally(() => {
            setIsTableLoad(false)
        })
    }

    useEffect(() => {
        refreshTable(testType, testSection)
        // eslint-disable-next-line
    }, [])

    return <Grid container spacing={2} style={{
        paddingLeft: 8,
        paddingRight: 8
    }}>
        <Grid item lg={6} sm={12} xs={12} >
            <Paper style={{padding:15}}>
            <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="demo-simple-select-label">
                    Test Type
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={testType}
                    onChange={onChangeTestType}
                >
                    <MenuItem value="TOEFL">{testAlias('TOEFL')}</MenuItem>
                    <MenuItem value="TOAFL">TOAFL</MenuItem>
                    <MenuItem value="B_INDONESIA">B.INDONESIA</MenuItem>
                </Select>
            </FormControl>
            </Paper>
        </Grid>
        <Grid item lg={6} sm={12} xs={12}>
            <Paper style={{padding:15}}>
                <FormControl className={classes.formControl} fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Test Section
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={testSection}
                        onChange={onChangeTestSection}
                    >
                        <MenuItem value="LISTENING">LISTENING</MenuItem>
                        <MenuItem value="STRUCTURE">STRUCTURE</MenuItem>
                        <MenuItem value="READING">READING</MenuItem>
                    </Select>
                </FormControl>
            </Paper>
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
            <MaterialTable
                icons={tableIcons}
                title={`${testSection.toLowerCase()}`}
                columns={column.columns}
                data={column.data}
                isLoading={isTableLoad}
                options={{
                    header: true,
                    actionsColumnIndex:4
                }}
                actions={[
                    {
                        icon: tableIcons.Add,
                        tooltip: 'Add Question',
                        isFreeAction: true,
                        onClick: (event) => {
                            
                        }
                    },
                    {
                        icon: tableIcons.Edit,
                        tooltip: 'Edit Question',
                        onClick: (event, rowData) => {
                            
                        }
                    },
                    {
                        icon: tableIcons.Delete,
                        tooltip: 'Delete Question',
                        onClick: (event, rowData) => {}
                    }
                ]}
                detailPanel={rowData => {
                    return (
                    <div dir={(testType === params.examType.TOAFL) ? "rtl": "ltr"}>
                        <Container align={(testType === params.examType.TOAFL) ? "right" : "left"}>
                        <br/>
                        <h1>Instruction:</h1>
                        <p className={(testType === params.examType.TOAFL) ? classes.arabicFont : undefined}>{(rowData.instruction) ? renderHTML(rowData.instruction) : "-"}</p>
                        </Container>
                    </div>
                    )
                }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
        </Grid>
    </Grid>
}

export default QuestionInstruction