import axios from 'axios'
import {api} from '../helper/utils'

export const getQuestions = (examtype, step) => {
    return new Promise(function(resolve, reject) {
        const st = step.toUpperCase()
        const ext = examtype.toUpperCase()
        axios.get(api(`question?examtype=${ext}&steps=${st}`)).then(res => {
            if(res.status === 200) {
                resolve(res.data.data)
            }else{
                reject(res.statusText)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    });
}

export const addQuestion = (data, token) => {
    return new Promise(function(resolve, reject) {
        axios.post(api("question"), data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const editQuestion = (id, data, token) => {
    return new Promise(function(resolve, reject) {
        axios.patch(api(`question/${id}`), data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteQuestion = (id, token) => {
    return new Promise(function(resolve, reject) {
        axios.delete(api(`question/${id}`), {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const importQuestion = (token, examtypeid, step, data) => {
    return new Promise(function(resolve, reject) {
        axios.post(api(`question/importexcel/${examtypeid}/${step}`), data, {
            headers: {'auth-token': token, 'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const getListeningAudioList = (token, examtypeid, audiotype) => {
    return new Promise(function(resolve, reject) {
        axios.get(api(`question/audiolist?examtype=${examtypeid}&audiotype=${audiotype}`), {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data.listfile)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const deleteListeningAudio = (token, examtypeid, audiotype, filename) => {
    return new Promise(function(resolve, reject) {
        axios.delete(api(`question/removeaudio?testtype=${examtypeid}&audiotype=${audiotype}&filename=${filename}`), {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data.message)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

export const uploadListeningAudio = (token, data) => {
    return new Promise(function(resolve, reject) {
        axios.post(api(`question/uploadaudio`), data,{
            headers: {'auth-token': token, 'Content-Type': 'multipart/form-data'}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data.message)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}