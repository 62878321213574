import React, {useState, useEffect} from 'react'
import { Grid, RadioGroup, FormControlLabel, Radio, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import renderHTML from 'react-render-html'
import clsx from 'clsx'
import ExpressionQuestion from '../component/expressionsQuestion'
import processString from 'react-process-string'
import { numberToArabic } from 'number-to-arabic'
import params from '../helper/params'

const useStyles = makeStyles(theme => ({
    arabicFont: {
        fontSize: 28,
        fontWeight: "bold",
        textAlign: "right",
        fontFamily: "Scheherazade, sans-serif"
    },
    normalFont: {
        fontSize: 16,
    },
    passage: {
        paddingRight: 16,
        textAlign: 'justify',
        paddingBottom: '100px;',
    },
    questionList: {
        paddingLeft: 16,
        borderLeft: '3px dotted #e0e0e0',
        paddingBottom: '100px;',
    }
}))

const Questions = props => {

    const examIndex = useSelector(state => state.examState)
    const examType = useSelector(state => state.examTypeState)
    const examStepCategory = useSelector(state => state.examStepCategory)
    const [answer, setAnswer] = useState("")
    const [multiAnswer, setMultiAnswer] = useState([[]])
    const classes = useStyles()
    const questionState = useSelector(state => state.questions)
    const listeningPart = useSelector(state => state.listeningPart)
    const letterOptions = (examType === params.examType.TOAFL) ? ['أ', 'ب', 'ج', 'د'] : ['A', 'B', 'C', 'D']
    const [qnumber, setQnumber] = useState(0)

    const handleChange = e => {        
        if(examIndex === params.examIndex.READING) {
            let questionNo = e.target.getAttribute('question_number')
            localStorage.setItem("answer", saveAnswer(localStorage.getItem("answer"), questionNo, e.target.value))
            // forceUpdate()
        }else{
            if(listeningPart === "b" || listeningPart === "c") {
                let questionNo = e.target.getAttribute('question_number')
                let indexNo = e.target.getAttribute('index_number')
                localStorage.setItem("answer", saveAnswer(localStorage.getItem("answer"), questionNo-1, e.target.value))
                localStorage.setItem("listening_answer", saveListeningAnswer(localStorage.getItem("listening_answer"), indexNo, e.target.value))
            }else{
                setAnswer(e.target.value)
                localStorage.setItem("answer", saveAnswer(localStorage.getItem("answer"), props.no, e.target.value))
            }
        }
    }

    const saveAnswer = (myanswer, no, answer) => {      
        var res = ""

        if (examIndex === params.examIndex.READING) {
            if (myanswer != null) {
                let mya = JSON.parse(myanswer)

                mya[questionState][no] = answer
                setMultiAnswer(mya)
                res = JSON.stringify(mya)
            } else {
                let mya = []
                
                props.examdata.map(() => mya.push([]))
                
                mya[questionState][no] = answer
                setMultiAnswer(mya)
                res = JSON.stringify(mya)                
            }
        } else {
            if (myanswer != null) {
                let mya = JSON.parse(myanswer)
                mya[no] = answer
                
                res = JSON.stringify(mya)
            } else {
                let mya = []
                mya[no] = answer
                res = JSON.stringify(mya)
            }
        }
        return res
    }

    const saveListeningAnswer = (myanswer, no, answer) => {
        var res = ""
        if(myanswer !== null) {
            let mya = JSON.parse(myanswer)

            mya[0][no] = answer
            setMultiAnswer(mya)
            res = JSON.stringify(mya)
        }else{
            let mya = []
                
            props.listening_part.questions.map(() => mya.push([]))
            
            mya[0][no] = answer
            setMultiAnswer(mya)
            res = JSON.stringify(mya)
        }

        return res;
    }

    const renderExpressionQuestions = (questions, options) => {
        let processed = ""
        let toaflregex = /<\s*u[^>]*>(.*?)<\/u>/
        let toeflregex = /<u>([a-zA-Z0-9 .?]+?)<\/u>/
        options.forEach((ans, i) => {
            processed = processString([{
                regex: (examType === params.examType.TOAFL) ? toaflregex : toeflregex, //regex to match a username
                fn: (key, result) => {
                    return <ExpressionQuestion 
                        key={result[1]} 
                        answer={result[1]} 
                        option={letterOptions[options.indexOf(result[1])]} 
                        checked={(result[1] === answer) ? true : false} 
                        no={props.no}
                    />;
                }
            }])(questions);
        })

        return processed
    }

    useEffect(() => {
        var listeningPartIndex = () => {
            if(listeningPart === "b") {
                return 0
            }else if(listeningPart === "c"){
                return 1
            }
        }

        if(examIndex === params.examIndex.READING) {
            if(!multiAnswer[questionState]) {
                let mya = []
                props.examdata.map(() => mya.push([]))
            }

            setQnumber(0)
            if(props.no === 0) {
                setQnumber(1)
            }else{
                var nqnumber = 1;
                props.examdata.forEach((v, i) => {
                    if(i < props.no) {
                        nqnumber += props.examdata[i].questions.length
                        
                        setQnumber(nqnumber)
                    }
                })
            }
        }else{
            if(listeningPart === "b" || listeningPart === "c") {
                if(props.listening_part && !multiAnswer[listeningPartIndex]) {
                    let mya = []
                    props.listening_part.questions.map(() => mya.push([]))
                }
            }else{
                setAnswer(props.value)
            }
        }
    }, [props, examIndex, listeningPart, multiAnswer, questionState])

    switch (props.steps) {
        case params.examStep.READING:
            return <div>
                <Grid container style={{border: '1px solid #e0e0e0', padding:'5px'}} dir={(examType === params.examType.TOAFL) ? "rtl" : "ltr"}>
                    <Grid item lg={8} md={8} sm={8}>
                        <div style={{overflowY: 'scroll', height: 570}}>
                            <span className={clsx(classes.passage, (examType === params.examType.TOAFL) ?classes.arabicFont : classes.normalFont)}>
                                {(props.questions.passage) ? renderHTML(props.questions.passage) : ""}
                            </span>
                        </div>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4}>
                        <div style={{overflowY: 'scroll', height: 570}}>
                            <div className={classes.questionList}>
                            {
                                props.questions.questions.map(function(question, no)  {
                                    
                                    return <div key={`${props.no}${no}`}>
                                            <div style={{paddingRight:20}}>
                                                <table>
                                                    <tbody>
                                                        <tr style={{verticalAlign:"top"}}>
                                                            <td><span className={(examType === params.examType.TOAFL) ? classes.arabicFont : classes.normalFont}>{(examType === params.examType.TOAFL) ? numberToArabic(qnumber+no): qnumber+no}.</span></td>
                                                            <td><span className={(examType === params.examType.TOAFL) ? classes.arabicFont : classes.normalFont}>{renderHTML(question.questions)}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div >
                                                <RadioGroup aria-label="answer" name="answer" value={(multiAnswer[questionState]) ? multiAnswer[questionState][no] : ""} onChange={handleChange} align={(examType === params.examType.TOAFL) ? "right" : "left"}>
                                                    {question.options.map((q, i) => <FormControlLabel 
                                                        key={`${props.no}${no+1}${i}`}
                                                        value={q}
                                                        control={<Radio key={`${props.no}${no+1}${i}`} color="primary" inputProps={{'question_number':no}}/>}
                                                    label={
                                                        <table cellSpacing="3">
                                                            <tbody>
                                                                <tr style={{verticalAlign:'top'}}>
                                                                    <td><span className={(examType === params.examType.TOAFL) ? classes.arabicFont : classes.normalFont}>({letterOptions[i]})</span></td>
                                                                    <td><span className={(examType === params.examType.TOAFL) ? classes.arabicFont : classes.normalFont}>{q}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    }
                                                        labelPlacement="end"    
                                                    />)}
                                                </RadioGroup>
                                            </div>
                                        <hr/>
                                    </div>
                                })
                            }
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        case params.examStep.STRUCTURE:
            return <div>
            <Grid container dir={(examType === params.examType.TOAFL) ? "rtl" : "ltr"}>
                <Grid item xs={12} md={12}>
                    {(examStepCategory === "expressions") ? <span className={(examType===params.examType.TOAFL) ? classes.arabicFont : classes.normalFont}>
                    {(examType === params.examType.TOAFL) ? numberToArabic(props.no+1) : props.no+1}. {renderExpressionQuestions(props.questions.questions, props.questions.options)} </span> : <div>
                        <span className={(examType===params.examType.TOAFL) ? classes.arabicFont : classes.normalFont}>
                        {(examType === params.examType.TOAFL) ? numberToArabic(props.no+1) : props.no+1}. {renderHTML(props.questions.questions)}
                        </span>
                        <br/>
                        <RadioGroup aria-label="answer" name="answer" value={answer || ""} onChange={handleChange} align={(examType === params.examType.TOAFL) ? "right" : "left"}>
                            {props.questions.options.map((q, i) => <FormControlLabel 
                                key={i}
                                value={q}
                                control={<Radio key={i} color="primary"/>}
                                label={
                                    <table cellSpacing="3">
                                        <tbody>
                                            <tr style={{verticalAlign:'top'}}>
                                                <td><span className={(examType === params.examType.TOAFL) ? classes.arabicFont : classes.normalFont}>({letterOptions[i]})</span></td>
                                                <td><span className={(examType === params.examType.TOAFL) ? classes.arabicFont : classes.normalFont}>{q}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }
                                labelPlacement="end"    
                            />)}
                        </RadioGroup>
                    </div>}
                </Grid>
            </Grid>
            </div>
        default:

            let halfGroup = (props.listening_part.questions) ? 
                Math.round(props.listening_part.questions.length / 2) 
                : 0

            return <div>
                {/* <Grid container dir="rtl"> */}
                <Grid container dir={(examType === params.examType.TOAFL) ? "rtl" : "ltr"}>
                    {(listeningPart === "a") ? <table style={{width:'100%'}}>
                        <tbody>
                            <tr>
                                <td style={{verticalAlign:"top", paddingRight:20}}>
                                    <span 
                                        className={(examType === params.examType.TOAFL) ? 
                                            classes.arabicFont 
                                            : classes.normalFont}>
                                                {(examType === params.examType.TOAFL) ? 
                                                    numberToArabic(props.no+1) 
                                                    : props.no+1}. 
                                    </span>
                                </td>
                                <td >
                                    <RadioGroup 
                                        aria-label="answer" 
                                        name="answer" 
                                        value={answer} 
                                        onChange={handleChange}
                                        align={(examType === params.examType.TOAFL) ? 
                                            "right" 
                                            : "left"}>
                                        {props.questions.options.map((q, i) => <FormControlLabel 
                                            key={i}
                                            value={q}
                                            control={<Radio key={i} color="primary"/>}
                                            label={
                                                <table cellSpacing="3">
                                                    <tbody>
                                                        <tr style={{verticalAlign:'top'}}>
                                                            <td>
                                                                <span 
                                                                    className={(examType === params.examType.TOAFL) ? 
                                                                        classes.arabicFont 
                                                                        : classes.normalFont}>
                                                                        ({letterOptions[i]})
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span 
                                                                    className={(examType === params.examType.TOAFL) ? 
                                                                        classes.arabicFont 
                                                                        : classes.normalFont}>
                                                                        {q}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            }
                                            labelPlacement="end"    
                                        />)}
                                    </RadioGroup>
                                </td>
                            </tr>
                        </tbody>
                    </table> : <Grid container>
                        <Grid key="one" item xs={6} md={6} style={{overflowY: 'scroll', height: 570}}>
                            <div style={{paddingBottom:200}}>
                                <table style={{
                                        borderCollapse: 'separate', 
                                        borderSpacing: '10px 15px', 
                                        width:'100%'
                                    }}>
                                    <tbody>
                                    {
                                        (props.listening_part) && props.listening_part.questions.map((group, no) => (no < halfGroup) && <tr key={no} style={{verticalAlign: "top"}}>
                                            <td style={{borderBottom: "1px dashed #e0e0e0"}}>
                                                <span 
                                                    className={(examType === params.examType.TOAFL) ? 
                                                        classes.arabicFont 
                                                        : classes.normalFont}>
                                                            {(examType === params.examType.TOAFL) ? 
                                                                numberToArabic(group.no) 
                                                                : group.no}. 
                                                </span>
                                            </td>
                                            <td style={{borderBottom: "1px dashed #e0e0e0"}}>
                                                <RadioGroup 
                                                    aria-label="answer" 
                                                    name="answer" 
                                                    value={multiAnswer[0][no] || ""} 
                                                    onChange={handleChange} 
                                                    align={(examType === params.examType.TOAFL) ? 
                                                        "right" 
                                                        : "left"
                                                    }>
                                                    {group.options.map((q, i) => <FormControlLabel 
                                                        key={i}
                                                        value={q}
                                                        control={<Radio key={i} 
                                                        color="primary" 
                                                        inputProps={{
                                                            'question_number':group.no, 
                                                            'index_number': no
                                                        }}
                                                    />}
                                                    label={
                                                        <table cellSpacing="3">
                                                            <tbody>
                                                                <tr style={{verticalAlign:'top'}}>
                                                                    <td>
                                                                        <span 
                                                                            className={(examType === params.examType.TOAFL) ? 
                                                                            classes.arabicFont 
                                                                            : classes.normalFont}>
                                                                                ({letterOptions[i]})
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span 
                                                                            className={(examType === params.examType.TOAFL) ? 
                                                                                classes.arabicFont 
                                                                                : classes.normalFont}>
                                                                                {q}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    }
                                                        labelPlacement="end"    
                                                    />)}
                                                </RadioGroup>
                                            </td>
                                        </tr>)
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </Grid>
                        <Grid key="two" item xs={6} md={6} style={{overflowY: 'scroll', height: 570}}>
                            <div style={{paddingBottom:200}}>
                                <table style={{
                                        borderCollapse: 'separate', 
                                        borderSpacing: '10px 15px', 
                                        width:'100%'
                                    }}>
                                    <tbody>
                                    {
                                        (props.listening_part) && props.listening_part.questions.map((group, no) => (no >= halfGroup) && <tr key={no} style={{verticalAlign: "top"}}>
                                            <td style={{borderBottom: "1px dashed #e0e0e0"}}>
                                                <span 
                                                    className={(examType === params.examType.TOAFL) ? 
                                                        classes.arabicFont 
                                                        : classes.normalFont}>
                                                            {(examType === params.examType.TOAFL) ? 
                                                                numberToArabic(group.no) 
                                                                : group.no}. 
                                                </span>
                                            </td>
                                            <td style={{borderBottom: "1px dashed #e0e0e0"}}>
                                                <RadioGroup 
                                                    aria-label="answer" 
                                                    name="answer" 
                                                    value={multiAnswer[0][no] || ""} 
                                                    onChange={handleChange} 
                                                    align={(examType === params.examType.TOAFL) ? 
                                                        "right" 
                                                        : "left"
                                                    }>
                                                    {group.options.map((q, i) => <FormControlLabel 
                                                        key={i}
                                                        value={q}
                                                        control={<Radio key={i} 
                                                        color="primary" 
                                                        inputProps={{
                                                            'question_number':group.no, 
                                                            'index_number': no
                                                        }}
                                                    />}
                                                    label={
                                                        <table cellSpacing="3">
                                                            <tbody>
                                                                <tr style={{verticalAlign:'top'}}>
                                                                    <td>
                                                                        <span 
                                                                            className={(examType === params.examType.TOAFL) ? 
                                                                                classes.arabicFont 
                                                                                : classes.normalFont}>
                                                                                    ({letterOptions[i]})
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span 
                                                                            className={(examType === params.examType.TOAFL) ? 
                                                                                classes.arabicFont 
                                                                                : classes.normalFont}>
                                                                                    {q}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    }
                                                        labelPlacement="end"    
                                                    />)}
                                                </RadioGroup>
                                            </td>
                                        </tr>)
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </Grid>
                    </Grid>   
                    }
                </Grid>
            </div>
    }

    
}

export default Questions