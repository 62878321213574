import React, {useState, useEffect} from 'react'
import clsx from 'clsx';
import { AppBar, Toolbar,IconButton, Typography, Menu, MenuItem, makeStyles, useTheme ,Button, Container, Drawer, List, ListItem, ListItemIcon, Divider, ListItemText } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import SettingsIcon from '@material-ui/icons/Settings';
import CssBaseline from '@material-ui/core/CssBaseline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useHistory, NavLink} from 'react-router-dom'
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LockIcon from '@material-ui/icons/Lock';
import { logout } from '../model/usermodel'
import {loginpass} from '../actions/userAction'
import {useDispatch} from 'react-redux'
import { showloader, hideloader } from '../actions/loaderActions';
import {show_alert} from '../actions/alertActions'
import lang from '../helper/localize'
import GroupAddIcon from '@material-ui/icons/GroupAdd';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    activemenu: {
        backgroundColor: '#eeeeee'
    },
    activeIcon: {
        color: "#fff"
    },
    navlink: {
        color:'inherit', 
        textDecoration:'none'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      hide: {
        display: 'none',
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        backgroundColor: "#fff",
        color:'#2196f3',
        height:20,
        justifyContent: 'flex-end',
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(8,0),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
  }));

const MainPage = props => {

    const theme = useTheme()
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const Component = props.component
    const route = props.route
    const menu = props.menu
    const token = localStorage.getItem('token')
    const dispatch = useDispatch()
    
    const [userName, setUesrname] = useState("")
    const [drawOpen, setOpen] = useState(true)
    var user = JSON.parse(localStorage.getItem('auth'))
    const history = useHistory()

    useEffect(() => {          
      if(!token && !user) {
        history.replace('/')
      }
      setUesrname((user != null) ? user.name : "")
      document.body.style.backgroundColor = "#dbdbdb";
    }, [token, user, history])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenu = event => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const handleProfile = () => {
      history.push('/console/myprofile')
    }

    const handleLogout = () => {
      dispatch(showloader(lang.loggingout))
      logout(token).then(res => {
        dispatch(loginpass())
        localStorage.removeItem('auth')
        localStorage.removeItem('token')
        history.replace('/login')
      }).catch(err => {
        dispatch(show_alert({
            status: 'error',
            message: err.message
        }))
      }).finally(() => {
        dispatch(hideloader())
      })
    }

    return <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={clsx(classes.appBar, {
          [classes.appBarShift]: drawOpen,
        })} style={{backgroundColor: "#003366"}}>
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, drawOpen && classes.hide)}
            >
                <MenuIcon />
            </IconButton>
          <Typography variant="h6" className={classes.title}>
            Console
          </Typography>
            <div>
              <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleMenu}
                startIcon={<AccountCircle />}
              >
                  {userName}
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={handleClose}
                open={open}
              >
                <MenuItem onClick={handleProfile}>
                    {/* <NavLink to="/console/profile" className={classes.navlink}> */}
                        <ListItemIcon><AccountBoxIcon/></ListItemIcon> {lang.profile}
                    {/* </NavLink> */}
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                        <ListItemIcon><LockIcon/></ListItemIcon> {lang.logout}
                </MenuItem>
              </Menu>
            </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} style={{paddingTop:0}}>
            <Container align="left" style={{paddingLeft: 10}}>
              <h1 style={{color:"#003366"}}>CBT</h1>
            </Container>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon/>}
            </IconButton>
        </div>
        <Divider/>
        <List style={{marginTop:-10}}>
            { (user && (user.role === "ADMIN" || user.role === "MANAGER" || user.role === "INVIGILATOR")) && 
            <div>
            <NavLink to="/console" className={classes.navlink}>
                <ListItem button={true} selected={(menu === "EXAM") ? true : false } >
                    <ListItemIcon><AssignmentIcon/></ListItemIcon>
                    <ListItemText primary={lang.exam} />
                </ListItem>
            </NavLink>
            </div>
            
            }

            { (user && (user.role === "ADMIN" || user.role === "MANAGER")) &&
                <NavLink to="/console/registration" className={classes.navlink}>
                    <ListItem button={true} selected={(menu === "REGISTRATION") ? true : false}>
                        <ListItemIcon><GroupAddIcon /></ListItemIcon>
                        <ListItemText primary={lang.register} />
                    </ListItem>
                </NavLink>
            }

            { (user && (user.role === "ADMIN" || user.role === "QUESTIONER")) && 
            <NavLink to="/console/questionbank" className={classes.navlink}>
                <ListItem button={true} selected={(menu === "QUESTION") ? true : false }>
                    <ListItemIcon><LiveHelpIcon /></ListItemIcon>
                    <ListItemText primary={lang.question_bank} />
                </ListItem>
            </NavLink>
            }
            { (user && (user.role === "ADMIN")) && 
              <div>
                <Divider/>
                <NavLink to="/console/accounts" className={classes.navlink}>
                  <ListItem button={true} selected={(menu === "ACCOUNT") ? true : false }>
                      <ListItemIcon><AccountCircle /></ListItemIcon>
                      <ListItemText primary={lang.account} />
                  </ListItem>
                </NavLink>
              </div>
            }
            { (user && (user.role === "ADMIN" || user.role === "MANAGER")) && 
              <div>
                <NavLink to="/console/settings" className={classes.navlink}>
                <ListItem button={true} selected={(menu === "SETTING") ? true : false }>
                    <ListItemIcon><SettingsIcon/></ListItemIcon>
                    <ListItemText primary="Setting" />
                </ListItem>
                </NavLink>
              </div>
            }
        </List>
      </Drawer>
      <main className={clsx(classes.content, {[classes.contentShift]: drawOpen})}>
        <Component route={route}/>
      </main>
    </div>
}

export default MainPage