import React, {forwardRef, useState, useEffect } from 'react'
import { Container, Grid } from '@material-ui/core'
import MaterialTable from 'material-table'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { getAccounts, activate_user, deleteAccount } from '../model/usermodel'
import { show_alert } from '../actions/alertActions'
import { show_dialog, hide_dialog } from '../actions/dialogActions'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import lang from '../helper/localize'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

const AccountManagement = props => {

    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    const [isTableLoad, setTableLoad] = useState(false)
    const [accountdata, setAccountData] = useState({
        columns: [
            {title: lang.name, field: "name"},
            {title: lang.email, field: "email"},
            {title: lang.role, field: "role"},
            {title: lang.status, field: "status"},
            {title: lang.register_date, field: "created_at"}
        ],
        data: []
    })

    const refreshTable = () => {
        setTableLoad(true)
        getAccounts(token).then(res => {
            res.data.forEach((dt, i) => {
                res.data[i].created_at = moment(dt.created_at).fromNow()
            })
            setAccountData({
                columns: accountdata.columns,
                data: res.data
            })
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            setTableLoad(false)
        })
    }

    useEffect(refreshTable, [])

    return <div>
        <Container maxWidth="lg">
            <Grid container spacing={2} style={{marginTop:20}}>
                <Grid item lg={12} md={12} xs={12}>
                    <MaterialTable 
                        icons={tableIcons}
                        title={lang.account_list}
                        columns={accountdata.columns}
                        data={accountdata.data}
                        isLoading={isTableLoad}
                        options={{
                          header: true,
                          actionsColumnIndex:5
                        }}
                        actions={[
                            {
                                icon: tableIcons.Check,
                                tooltip: 'Activate user',
                                onClick: (event, rowData) => {
                                    dispatch(show_dialog({
                                        title: lang.attention,
                                        message: lang.activateconfirm
                                    }, () => {
                                        setTableLoad(true)
                                        dispatch(hide_dialog())
                                        activate_user(rowData._id, {status: 'ACTIVE'}, token).then(res => {
                                            dispatch(show_alert({
                                                status: 'success',
                                                message: res.message
                                            }))
                                            accountdata.data[accountdata.data.indexOf(rowData)].status = 'ACTIVE'
                                            setAccountData({
                                                columns: accountdata.columns,
                                                data: accountdata.data
                                            })
                                        }).catch(err => {                                            
                                            dispatch(show_alert({
                                                status: 'error',
                                                message: err.message
                                            }))
                                        }).finally(() => {
                                            setTableLoad(false)
                                        })
                                    }))
                                }
                            },
                            {
                                icon: tableIcons.Delete,
                                tooltip: 'Delete user',
                                onClick: (event, rowData) => {
                                    dispatch(show_dialog({
                                        title: lang.attention,
                                        message: lang.deleteconfirm
                                    }, () => {
                                        setTableLoad(true)
                                        dispatch(hide_dialog())
                                        deleteAccount(rowData._id, token).then(res => {
                                            dispatch(show_alert({
                                                status: 'success',
                                                message: res.message
                                            }))
                                            setAccountData(prevState => {
                                                const data = [...prevState.data];
                                                data.splice(data.indexOf(rowData), 1);
                                                return { ...prevState, data };
                                            })
                                        }).catch(err => {
                                            dispatch(show_alert({
                                                status: 'error',
                                                message: err.message
                                            }))
                                        }).finally(() => {
                                            setTableLoad(false)
                                        })
                                    }))
                                }
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        </Container>
    </div>
}

export default AccountManagement