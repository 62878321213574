import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Divider, Tab, Tabs, TextField, FormControl, Button } from '@material-ui/core'
import { useForm, ErrorMessage, Controller } from 'react-hook-form'
import { updateprofile } from '../model/usermodel'
import { useDispatch } from 'react-redux'
import {showloader, hideloader} from '../actions/loaderActions'
import {show_alert} from '../actions/alertActions'
import lang from '../helper/localize'

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      '& > *': {
        marginTop: theme.spacing(3),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(17),
      height: theme.spacing(17),
    },
    container: {
        padding: theme.spacing(2),
    },
    input: {
        marginBottom: 10
    }
  }));

const MyProfile = props => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const accdata = JSON.parse(localStorage.getItem('auth'))
    const { register, errors, control, handleSubmit, watch } = useForm()

    const [tabIndex, setTabIndex] = useState(0)
    const [userdata, setUserData] = useState({
        name: accdata.name,
        role: accdata.role,
        phone: accdata.phone,
        email: accdata.email,
        _id: accdata._id
    })

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleChangepassword = async (data) => {
        dispatch(showloader(lang.update_password))
        updateprofile(token, userdata._id, {
            password: data.newpassword
        }).then(res => {
            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    const handleUpdateProfile = (data) => {
        dispatch(showloader(lang.update_profile))
        updateprofile(token, userdata._id, data).then(res => {
            let udata = {
                name: res.data.name,
                email: res.data.email,
                phone: res.data.phone,
                _id: res.data._id,
                role: res.data.role
            }
            
            setUserData(udata)

            localStorage.setItem('auth', JSON.stringify(udata))

            dispatch(show_alert({
                status: 'success',
                message: res.message
            }))
        }).catch(err => {
            dispatch(show_alert({
                status: 'error',
                message: err.message
            }))
        }).finally(() => {
            dispatch(hideloader())
        })
    }

    return <div className={classes.root}>
        <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item lg={3} sm={3} xs={12}>
                        <Paper className={classes.container}>
                            <div style={{textAlign:'center'}}>
                                <img alt="User profile" src="/user_placeholder.png" className={classes.large} />
                                <h2>{userdata.name}</h2>
                            </div>
                            
                            <Divider/>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Role</td>
                                        <td>: {(userdata.role) ? userdata.role : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>: {(userdata.email) ? userdata.email : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone</td>
                                        <td>: {(userdata.phone) ? userdata.phone : "-" }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Paper>
                    </Grid>
                    <Grid item lg={9} sm={9} xs={12}>
                        <Paper>
                            <Tabs
                                value={tabIndex}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleTabChange}
                                aria-label="menu tab">
                                    <Tab label={lang.update_profile} />
                                    <Tab label={lang.change_password} />
                            </Tabs>
                            <Divider/>
                            <div className={classes.container}>
                            {
                                (tabIndex === 0) ?
                                <div>
                                    <form key={0} onSubmit={handleSubmit(handleUpdateProfile)}>
                                        <FormControl fullWidth className={classes.input}>
                                            <Controller as={
                                                <TextField
                                                    ref={register}
                                                    helperText={(errors.name) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="name"/>}
                                                    label="Name"
                                                    placeholder={lang.input_name}
                                                    type="text"
                                                />
                                            } name="name" rules={{required: lang.require_name}} defaultValue={(userdata.name) ? userdata.name : accdata.name} control={control} />
                                        </FormControl>
                                        <FormControl fullWidth className={classes.input}>
                                            <Controller as={
                                                <TextField
                                                    key="email"
                                                    ref={register}
                                                    helperText={(errors.email) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="email"/>}
                                                    label="Email"
                                                    placeholder={lang.input_email}
                                                    type="email"
                                                />
                                            } name="email" control={control} defaultValue={(userdata.email) ? userdata.email: accdata.email} rules={{required: lang.require_email}} />
                                        </FormControl>
                                        <FormControl fullWidth className={classes.input}>
                                            <Controller as={
                                                <TextField
                                                    ref={register}
                                                    helperText={(errors.phone) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="phone"/>}
                                                    label={lang.phone}
                                                    placeholder={lang.input_phone}
                                                    type="number"
                                                />
                                            } name="phone" control={control} defaultValue={(userdata.phone) ? userdata.phone : accdata.phone} rules={{required: lang.require_phone}} />
                                        </FormControl>
                                        <Button variant="contained" fullWidth color="primary" type="submit">SAVE</Button>
                                    </form>
                                </div>
                                :
                                <div>
                                    <form key={1} onSubmit={handleSubmit(handleChangepassword)}>
                                        {/* <FormControl fullWidth className={classes.input}>
                                            <Controller as={
                                                <TextField
                                                    ref={register}
                                                    helperText={(errors.oldpassword) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="oldpassword"/>}
                                                    label="Old Password"
                                                    placeholder="Input your old password"
                                                    type="password"
                                                />
                                            } name="oldpassword" control={control} defaultValue="" rules={{required: "Old password is required"}} />
                                        </FormControl> */}
                                        <FormControl fullWidth className={classes.input}>
                                            <Controller as={
                                                <TextField
                                                    ref={register({name: 'newpassword'})}
                                                    helperText={(errors.newpassword) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="newpassword"/>}
                                                    label={lang.label_new_password}
                                                    placeholder={lang.input_new_password}
                                                    type="password"
                                                />
                                            } name="newpassword" control={control} defaultValue="" rules={{required: "New password is required"}} />
                                        </FormControl>
                                        <FormControl fullWidth className={classes.input}>
                                            <Controller as={
                                                <TextField
                                                    ref={register}
                                                    helperText={(errors.conpassword) && <ErrorMessage as={<span style={{color:'red'}} />} errors={errors} name="conpassword"/>}
                                                    label={lang.label_confirmpassword}
                                                    placeholder={lang.confirm_password}
                                                    type="password"
                                                />
                                            } name="conpassword" control={control} defaultValue="" rules={{required: lang.require_confirmpassword, validate: val => (val === watch('newpassword') || lang.nomatch_confirmpassword)}} />
                                        </FormControl>
                                        <Button variant="contained" fullWidth color="primary" type="submit">{lang.change_password}</Button>
                                    </form>
                                </div>
                            }
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
        </Container>
    </div>

}

export default MyProfile