import LocalizedStrings from 'react-localization'

let lang = new LocalizedStrings({
    en: {
        add: "Add",
        edit: "Edit",
        delete: "Delete",
        name: "Name",
        role: "Role",
        status: "Status",
        action: "Actions",
        no_id: "NIK/NIP",
        email: "Email",
        phone: "Phone",
        occupation: "Occupation",
        faculty: "Faculty",
        majors: "Majors",
        loading: "Loading data",
        attention: "Attention",
        deleteconfirm: "Are you sure want to delete this data?",
        activateconfirm: "Are you sure want to activate this account?",
        login: "Login",
        logout: "Logout",
        register: "Register",
        register_date: "Register Date",
        help: "Help",
        warning: "Warning",
        next: "Next",
        prev: "Previous",
        auth: "Authenticating",
        token: "Token",
        token_warning: "Input your registration token to check your participation on the test. Test will start at predetermined time not when you input your token. Make sure you attend 15 minutes before the test is started.",
        token_input: "Input your token",
        token_activation: "Activating token",
        start_test: "Start",
        process: "Processing your request",
        reset_password: "Reset password",
        input_email: "Input your email",
        require_email: "Email is reqired",
        exist_email: "Email {0} already in use",
        input_password: "Input your password",
        require_password: "Password is required",
        label_confirmpassword: "Confirm Password",
        confirm_password: "Confirm your password",
        require_confirmpassword: "Confirm password is required",
        nomatch_confirmpassword: "Password not match",
        change_password: "Change password",
        label_new_password: "New Password",
        input_new_password: "Input your new Password",
        label_old_password: "Old Password",
        input_old_password: "Input your old Password",
        input_name: "Input your name",
        require_name: "Name is required",
        input_phone: "Input your phone number",
        require_phone: "Phone number is required",
        input_nik: "Input your NIK/NIP",
        require_nik: "NIK/NIP is required",
        forget_password: "Forget Password",
        forgetpassword_message: "Input your Email address, we will send new reset password to your email.",
        back_to_login: "Back to login",
        back_to_home: 'Back to home screen',
        send_email: 'Send Email',
        next_step_message: 'Are you sure you want to go to the next session? Make sure you have answered all the question because you cannot go back to the previous section.',
        end_test_message: "Are you sure you want to end this test?",
        processing_result: "Processing results",
        help_step_one: "This is the Test Section Indicator. You will go to the next section if you click Next on the last question. Make sure you have answered all the questions because you cannot go back to the previous section.",
        help_step_two: "This is the Exam Timer. The exam will end if the timer reaches zero.",
        help_step_three: "This is the Exam Number Indicator. You can click the number to jump into a specific question number.",
        help_step_four: "This is the Navigation Button. It is used to navigate through all questions one by one. If you click Next at the last number, you will be prompted to go to the next section. Make sure you have answered all the questions as you cannot go back to the previous section.",
        help_step_five: "This is Help Button to show the interface tour again",
        help_step_six: "This is help button, to show this interface tour again.",
        exam: "Exam",
        exam_feeconfirm: "Are you sure {0} already pay the exam fee?",
        all_questions: "All Questions",
        question_bank: "Questions bank",
        account: "Account",
        account_list: "Account list",
        profile: "Profile",
        loggingin: "Logging in",
        loggingout: "Logging off",
        processing_new_exam: "Processing new Exam",
        processing_delete: "Deleting data",
        delete_exam: "Delete Exam",
        edit_exam: "Edit exam",
        participant_list: "Participant List",
        exam_time_validation: "Cannot add exam schedule, time is missing!",
        update_password: "Updating Password",
        update_profile: "Updating Profile",
        placeholder_options: "Input Options",
        label_options: "Options {0}",
        required_options: "Options {0} is required",
        label_questions: "Questions",
        input_questions: "Input Question",
        require_questions: "Question {0} is required",
        send_email_process: "Sending Email",
        questions_form: "Question Form",
        import_from_excel: "Import from excel",
        drag_drop_click: "Drag 'n' drop some files here, or click to select files",
        download_template: "Download Template",
        venue_validation: "You need to select venue to held this exam.",
        quit_app: "Are you sure you want to quit?",
        instruction_alert: "During listening comprehension test, the <strong>Next</strong> and <strong>Back</strong> buttons will not be active. <br/><br/>After you hear each question, you will have 6 seconds to answer. <br/><br/>Then the computer will automatically move to the next question.<br/><br/>The test will take between 30 and 40 minutes.<br/><br/><br/>Click OK to start the test.",
        details: "Details",
        answer_time: "Answer time",
        questions: "Questions",
        passage_no: "Passage no",
        part: "Part",
        reload_audio_message: "Audio not loaded, please reload...",
        payment_receipt: "Payment receipt",
        result_saved: "Your score is saved in our server",
        result_fail_saved: "Your score is not saved in our server, click send email to resend your score",
        download_skl: "Download SKL",
        save_exam_score: "Save Score"
    },
    id:{
        add: "Tambah",
        edit: "Ubah",
        delete: "Hapus",
        name: "Nama",
        role: "Wewenang",
        status: "Status",
        action: "Tindakan",
        no_id: "NIK/NIP",
        email: "Email",
        phone: "Telpon",
        occupation: "Pekerjaan",
        faculty: "Fakultas",
        majors: "Jurusan",
        loading: "Mempersiapkan data",
        attention: "Perhatian",
        deleteconfirm: "Apakah Anda yakin ingin menghapus data ini?",
        activateconfirm: "Apakah Anda yakin ingin mengaktifkan Akun ini?",
        login: "Masuk",
        logout: "Keluar",
        register: "Daftar",
        register_date: "Tanggal Daftar",
        help: "Bantuan",
        warning: "Peringatan",
        next: "Berikutnya",
        prev: "Sebelumnya",
        auth: "Otentikasi",
        token: "Token",
        token_warning: "Masukan token pendaftaran Anda. Ujian akan dimulai pada saat yang ditentukan, tidak pada saat Anda memasukan token. Pastikan Anda hadir 15 menit sebelum ujian dimulai.",
        token_input: "Masukan token Anda",
        token_activation: "Mengaktifkan Token",
        start_test: "Mulai",
        process: "Memproses permintaan Anda",
        reset_password: "Setel ulang kata sandi",
        input_email: "Masukan email Anda",
        require_email: "Email harus diisi",
        exist_email: "Email {0} sudah dipakai",
        input_password: "Masukan kata sandi Anda",
        require_password: "Kata sandi harus diisi",
        label_confirmpassword: "Confirm Password",
        confirm_password: "Konfirmasi kata sandi Anda",
        require_confirmpassword: "Konfirmasi kata sandi harus diisi",
        nomatch_confirmpassword: "Kata sandi dan Konfirmasi kata sandi tidak sama",
        change_password: "Ubah kata sandi",
        label_new_password: "Kata sandi baru",
        input_new_password: "Masukan kata sandi baru Anda",
        label_old_password: "Kata sandi lama",
        input_old_password: "Masukan kata sandi lama Anda",
        input_name: "Masukan nama Anda",
        require_name: "Nama harus diisi",
        input_phone: "Masukan nomor telpon Anda",
        require_phone: "Nomor telpon harus diisi",
        input_nik: "Masukan NIP/NIK Anda",
        require_nik: "NIK/NIP harus diisi",
        forget_password: "Lupa kata sandi",
        forgetpassword_message: "Masukan email Anda, kami akan kirimkan password baru ke email Anda.",
        back_to_login: "Kembali",
        back_to_home: 'Kembali ke halaman utama',
        send_email: 'Kirim Email',
        next_step_message: 'Apakah Anda yakin untuk melanjutkan ke sesi berikutnya? Pastikan Anda sudah menjawab semua pertanyaan di sesi ini, Anda tidak bisa kembali ke sesi sebelumnya.',
        end_test_message: "Apakah Anda yakin ingin mengakhiri ujian ini?.",
        processing_result: "Memproses hasil ujian.",
        help_step_one: "Bagian ini menunjukan posisi Anda di sesi ujian, Anda akan diarahkan ke sesi berikutnya bila Anda clik tombol berikutnya pada nomor terakhir di sesi saat ini. Pastikan Anda sudah menjawab semua soal di sesi saat ini, karena Anda tidak dapat kembali ke sesi sebelumnya.",
        help_step_two: "Bagian ini menunjukan sisa waktu ujian, ujian akan berakhir bila waktu sudah menunjukan angka nol",
        help_step_three: "Bagian ini menunjukan posisi nomor soal, Anda dapat click pada nomor soal untuk menuju ke nomor tersebut",
        help_step_four: "Ini adalah bagian menjawab soal ujian. Pastikan Anda menjawab semua soal.",
        help_step_five: "Tombol ini dipergunakan untuk navigasi ke soal berikutnya dan soal sebelumnya",
        help_step_six: "Tombol ini akan menampilkan tampilan bantuan",
        exam: "Ujian",
        exam_feeconfirm: "Apa Anda yakin {0} sudah membayar biaya ujian?",
        all_questions: "Daftar soal",
        question_bank: "Bank soal",
        account: "Akun",
        account_list: "Daftar Akun",
        profile: "Profil",
        loggingin: "Proses masuk",
        loggingout: "Proses keluar",
        processing_new_exam: "Membuat ujian baru",
        processing_delete: "Menghapus data",
        delete_exam: "Hapus ujian",
        edit_exam: "Ubah ujian",
        participant_list: "Daftar peserta",
        exam_time_validation: "Tidak dapat membuat ujian. Harap memasukan waktu ujian!",
        update_password: "Memperbarui kata sandi",
        update_profile: "Memperbarui Profil",
        placeholder_options: "Masukan Opsi",
        label_options: "Opsi {0}",
        required_options: "Opsi {0} harus diisi",
        label_questions: "Soal",
        input_questions: "Masukan Soal",
        require_questions: "Soal {0} harus diisi",
        send_email_process: "Mengirim Email",
        questions_form: "Formulir Soal",
        import_from_excel: "Impor dari excel",
        drag_drop_click: "Seret dan letakkan beberapa file di sini, atau klik untuk memilih file",
        download_template: "Unduh Templat",
        venue_validation: "Anda harus menentukan ruangan diamana ujian ini akan diadakan",
        quit_app: "Apa Anda yakin ingin keluar aplikasi?",
        instruction_alert: "Selama test listening, tombol <strong>Berikutnya</strong> dan <strong>Kembali</strong> tidak dapat digunakan. <br/><br/>Setelah Anda mendengarkan pertanyaan, Anda akan diberi waktu selama {0} detik untuk menjawab soal tersebut. <br/><br/>Kemudian secara otomatis komputer akan meneruskan ke soal berikutnya.<br/><br/>Test akan berlansung selama 30 sampai 40 menit.<br/><br/><br/>Klik OK untuk mulai test.",
        details: "Selengkapnya",
        answer_time: "Waktu Menjawab",
        questions: "Soal",
        passage_no: "Nomor Bacaan",
        part: "Bagian",
        reload_audio_message: "Audio gagal diputar, muat ulang...",
        payment_receipt: "Bukti Pembayaran",
        result_saved: "Score Anda sudah tersimpan di server kami",
        result_fail_saved: "Score Anda belum tersimpan di server kami, klik tombol kirim email untuk mengirim ulang score Anda",
        download_skl: "Unduh SKL",
        save_exam_score: "Simpan Score"
    }
})

export default lang