import axios from 'axios'
import {api} from '../helper/utils'

/**
 * 
 * @param {String} token - Token String
 * @param {Object} options - {examtype: ('TOEFL,TOAFL,B.INDO'), examstep: ('LISTENING', 'STRUCTURE', 'READING')}
 */
export const GetInstruction = (token, {
    examType,
    examStep
}) => {
    return new Promise((resolve, reject) => {
        axios.get(api(
            `instruction?examtype=${examType}&examstep=${examStep}`
        ), {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

/**
 * 
 * @param {String} token - user authentication token
 * @param {object} data - instruction data
 */
export const AddInstruction = (token, data) => {
    return new Promise((resolve, reject) => {
        axios.post('instruction', data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

/**
 * 
 * @param {String} token - user authentication token
 * @param {String} id - instruction id
 * @param {object} data - instruction data
 */
export const EditInstruction = (token, id, data) => {
    return new Promise((resolve, reject) => {
        axios.patch(`instruction/${id}`, data, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}

/**
 * 
 * @param {String} token - user authentication token
 * @param {String} id - instruction id 
 */
export const DeleteInstruction = (token, id) => {
    return new Promise((resolve, reject) => {
        axios.delete(`instruction/${id}`, {
            headers: {'auth-token': token}
        }).then(res => {
            if(res.status === 200) {
                resolve(res.data)
            }else{
                reject(res.data.message)
            }
        }).catch(err => reject((err.response) ? err.response.data : err))
    })
}