import React, {useState, useEffect, forwardRef, useRef} from 'react'
import { Container, Grid, IconButton } from '@material-ui/core'
import MaterialTable from 'material-table';
import moment from 'moment'

import AddBox from '@material-ui/icons/AddBox';
import PrintIcon from '@material-ui/icons/Print';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';

import { getAllParticipant, deleteParticipant, activateToken } from '../model/participantmodel'
import {useDispatch} from 'react-redux'
import { show_dialog, hide_dialog } from '../actions/dialogActions'
import { showloader, hideloader } from '../actions/loaderActions';
import { show_alert } from '../actions/alertActions';
import ImportParticipantForm from './formImportParticipant'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReactToPrint from 'react-to-print';
import lang from '../helper/localize'
import { testAlias, api } from '../helper/utils'

const Participant = props => {
    const token = localStorage.getItem('token')
    const dispatch = useDispatch()
    const componentRef = useRef()

    const [openImportForm, setOpenImportForm] = useState(false)
    const [isImport, setImport] = useState(false)
    const handleOpenImportForm = () => {
      setOpenImportForm(!openImportForm)
    }

    var [editParticipantData, setEditParticipantData] = useState({
      "name": "",
      "nid": "",
      "gender": "female",
      "birthplace": "",
      "birthdate": null,
      "email": "",
      "phone": "",
      "address": "",
      "programValue": "",
      "uinFaculty": "",
      "uinMajors": ""
    })

    const [isTableLoad, setIsTableLoad] = useState(false)
    const [participantData, setParticipantData] = useState({
        columns: [
          { title: 'Participant', field: 'name' },
          { title: 'Token', field: 'token' },
          { title: 'Status', field: 'status' },
          { title: 'Register', field: 'created_at' },
          { title: 'Exam Status', field: 'examlimit' }
        ],
        data: []
    });
    // const [participantData, setParticipantData] = useState(props.participantData);
    var user = JSON.parse(localStorage.getItem('auth'))

    const refreshTable = (tid, step) => {
      setIsTableLoad(true)
      getAllParticipant(token, props.examid).then(res => {
        
        if(res.data.length !== 0) {
          res.data.forEach((dt, i) => {
            res.data[i].created_at = moment(dt.created_at).fromNow()
            res.data[i].examlimit = (res.data[i].examlimit === 0) ? "NOT START" : (res.data[i].examlimit === 1 && res.data[i].result.length === 0 && !res.data[i].totalresult) ? "LOGIN" : (res.data[i].totalresult) ? "DONE" : "START"
            if(res.data[i].exam !== null) {
              res.data[i].exam.title = `${testAlias(dt.exam.examtype)} | ${moment(dt.exam.starttime).format("YYYY-MM-DD HH:mm")}`
            }else{
              res.data[i].exam = {'title': 'Exam not exist'}
            }
          })
        }
        
        setParticipantData({
          columns: participantData.columns,
          data: res.data
        })
      }).catch(err => {
        dispatch(show_alert({
          status: "error",
          message: err.message
        }))
      }).finally(() => {
        setIsTableLoad(false)
      })
    }

    useEffect(() => {
      setParticipantData(props.participantData)
    }, [props.participantData])

    const tableIcons = {
      DescriptionIcon: forwardRef((props, ref) => <DescriptionIcon {...props} ref={ref} />),
      CloseIcon: forwardRef((props, ref) => <CloseIcon {...props} ref={ref} />),
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Print: forwardRef((props, ref) => <PrintIcon {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    return <div>
        <Container maxWidth="lg">
            <div style={{display:'none'}}>
              <ReactToPrint 
                  trigger={() => <IconButton id="doPrintParticipant"><PrintIcon/></IconButton>}
                  content={() => componentRef.current}
              />
            </div>
            <Grid container style={{marginTop:20}} spacing={2}>
              <Grid item lg={12} sm={12} xs={12}>
                  <MaterialTable
                      icons={tableIcons}
                      title={props.title}
                      columns={participantData.columns}
                      data={participantData.data}
                      isLoading={isTableLoad}
                      options={{
                        header: true,
                        actionsColumnIndex:5
                      }}
                      actions={[
                        {
                          icon: tableIcons.Print,
                          tooltip: "Print participant",
                          isFreeAction: true,
                          onClick: (event, _) => {
                            document.getElementById("doPrintParticipant").click()
                          }
                        },
                        {
                          icon: tableIcons.DescriptionIcon,
                          disabled: (user.role === "ADMIN" || user.role === "MANAGER") ? false : true,
                          tooltip: "Import participant",
                          isFreeAction: true,
                          onClick: (event, _) => {
                            setOpenImportForm(true)
                            setImport(true)
                          }
                        },
                        {
                          icon: tableIcons.Export,
                          disabled: (user.role === "ADMIN" || user.role === "MANAGER") ? false : true,
                          tooltip: "Export participant",
                          isFreeAction: true,
                          onClick: (event, _) => {
                            window.open(api("exam/export-excel/"+props.examid), '_self', 'toolbar=0,location=0,menubar=0');
                          }
                        },
                        {
                          icon: tableIcons.Add,
                          disabled: (user.role === "ADMIN" || user.role === "MANAGER") ? false : true,
                          tooltip: "Add participant",
                          isFreeAction: true,
                          onClick: (event, _) => {
                            setEditParticipantData({
                              "id": 0,
                              "name": "",
                              "nid": "",
                              "gender": "female",
                              "birthplace": "",
                              "birthdate": null,
                              "email": "",
                              "phone": "",
                              "address": "",
                              "programValue": "s1",
                              "uinFaculty": "",
                              "uinMajors": ""
                            })
                            setOpenImportForm(true)
                            setImport(false)
                          }
                        },
                        {
                          icon: tableIcons.CloseIcon,
                          tooltip: "Close Participant List",
                          isFreeAction: true,
                          onClick: (event, _) => props.onClose()
                        },
                        {
                          icon: tableIcons.Check,
                          tooltip: 'Activate token',
                          disabled: (user.role === "ADMIN" || user.role === "MANAGER") ? false : true,
                          onClick: (event, rowData) => {
                            dispatch(show_dialog({
                              title: lang.attention,
                              message: lang.formatString(lang.exam_feeconfirm, rowData.name)
                            }, () => {
                              dispatch(hide_dialog())
                              dispatch(showloader(lang.token_activation))
                              
                              activateToken({
                                id: rowData._id,
                                email: rowData.email
                              }, token).then(res => {
                                dispatch(show_alert({
                                  status: "success",
                                  message: res.message
                                }))
                              }).catch(err => {
                                dispatch(show_alert({
                                  status: 'error',
                                  message: err.message
                                }))
                              }).finally(() => {
                                dispatch(hideloader())
                                refreshTable()
                              })
                            }))
                          }
                        },
                        {
                          icon: tableIcons.Edit,
                          tooltip: 'Edit Participant',
                          disabled: (user.role === "ADMIN" || user.role === "MANAGER") ? false : true,
                          onClick: (event, rowData) => {
                            console.log({rowData});
                            setEditParticipantData({
                              "id": rowData._id,
                              "name": rowData.name,
                              "nid": rowData.nid,
                              "gender": rowData.gender,
                              "birthplace": rowData.birthplace,
                              "birthdate": moment(rowData.birthdate).format('YYYY-MM-DD'),
                              "email": rowData.email,
                              "phone": rowData.phone,
                              "address": rowData.address,
                              "programValue": (rowData.program) ? rowData.program.toLowerCase() : "",
                              "uinFaculty": (rowData.faculty) ? rowData.faculty : "",
                              "uinMajors": (rowData.majors) ? rowData.majors : ""
                            })
                            setOpenImportForm(true)
                            setImport(false)
                          }
                        },
                        {
                          icon: tableIcons.Delete,
                          tooltip: 'Delete Participant',
                          onClick: (event, rowData) => {
                            dispatch(show_dialog({
                              title: lang.attention,
                              message: lang.deleteconfirm
                            }, () => {
                              dispatch(hide_dialog())
                              dispatch(showloader("Deleting data..."))
                              deleteParticipant(rowData._id, token).then(res => {
                                dispatch(show_alert({
                                  status: 'success',
                                  message: res.message
                                }))
                              }).catch(err => {
                                dispatch(show_alert({
                                  status: "error",
                                  message: err.message
                                }))
                              }).finally(() => {
                                dispatch(hideloader())
                                refreshTable()
                              })
                            }))
                          }
                        }
                      ]}
                      detailPanel={rowData => {
                        return <Container spacing={2}>
                          <table>
                            <tbody>
                              <tr>
                                <td>NIP/NIK/NIM</td>
                                <td>: {rowData.nid}</td>
                              </tr>
                              <tr>
                                <td>Email</td>
                                <td>: {rowData.email}</td>
                              </tr>
                              <tr>
                                <td>Phone</td>
                                <td>: {rowData.phone}</td>
                              </tr>
                              <tr>
                                <td>Token</td>
                                <td>: <strong>{(rowData.token) ? rowData.token : "-"}</strong></td>
                              </tr>
                              <tr>
                                <td>Score</td>
                                <td>: <strong>{(rowData.totalresult) ? rowData.totalresult : "-"}</strong></td>
                              </tr>
                              {(rowData.occupation) ? <tr>
                                <td>Occupation</td>
                                <td>: {rowData.occupation}</td>
                              </tr> : null}
                              {(rowData.faculty) ? <tr>
                                <td>Faculty</td>
                                <td>: {rowData.faculty}</td>
                              </tr> : null}
                              {(rowData.majors) ? <tr>
                                <td>Majors</td>
                                <td>: {rowData.majors}</td>
                              </tr> : null}
                              {(rowData.program) ? <tr>
                                <td>Program</td>
                                <td>: {rowData.program.toUpperCase()}</td>
                              </tr> : null}
                            </tbody>
                          </table>
                        </Container>
                      }}
                      onRowClick={(event, rowData, togglePanel) => togglePanel()}
                      />
              </Grid>
            </Grid>
        </Container>
        <ImportParticipantForm open={openImportForm} handleClose={handleOpenImportForm} isImport={isImport} loadData={refreshTable} examid={props.examid} editData={editParticipantData} />
        <div style={{display:'none'}}>
            <ParticipantPDF ref={componentRef} examtitle={props.title} participantdata={participantData.data}/>
        </div>
    </div>
}

class ParticipantPDF extends React.Component {
  render() {
    return(
      <Container maxWidth="lg">
        <Grid container>
          <Grid item lg={12} sm={12} xs={12}>
            <h1><u>Participant List</u></h1>
            <h3>{this.props.examtitle}</h3> 
          </Grid>
          <Grid item lg={12} sm={12} xs={12}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>NO</TableCell>
                  <TableCell>NAME</TableCell>
                  <TableCell>NIK</TableCell>
                  <TableCell>PHONE</TableCell>
                  <TableCell>EMAIL</TableCell>
                  <TableCell>TOKEN</TableCell>
                  <TableCell>SCORE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.participantdata.map((v, i) => {
                  return(<TableRow key={i}>
                    <TableCell>{(i+1)}</TableCell>
                    <TableCell>{(v.name) ? v.name : "-"}</TableCell>
                    <TableCell>{(v.nik) ? v.nik : "-"}</TableCell>
                    <TableCell>{(v.phone) ? v.phone : "-"}</TableCell>
                    <TableCell>{(v.email) ? v.email : "-"}</TableCell>
                    <TableCell>{(v.token) ? v.token : "-"}</TableCell>
                    <TableCell>{(v.totalresult) ? v.totalresult : "-"}</TableCell>
                  </TableRow>)
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default Participant