import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { jump_questions, processing_jump, set_exam_step_category } from '../actions/examActions'
import { numberToArabic } from 'number-to-arabic'
import params from '../helper/params'

const useStyle = makeStyles(theme => ({
    buttonNumber: {
        '&:hover':{
            backgroundColor: '#009688',
            color: '#fff',
            cursor: 'pointer'
        },
        margin:3,
        backgroundColor: '#fff',
        border: '1px solid #009688',
        width: 30,
        height: 30
    },
    buttonActive: {
        backgroundColor: '#263238',
        border: '1px solid #607d8b',
        color: '#fff'
    },
    buttonAnswer: {
        backgroundColor: '#009688',
        border: '1px solid #607d8b',
        color: '#fff',
    }
}))

const MiniMapno = props => {

    const classes = useStyle()
    const dispatch = useDispatch()
    const questionIndex = useSelector(state => state.questions)
    const examdata = JSON.parse(localStorage.getItem('examdata'))
    const examIndex = useSelector(state => state.examState)
    const examType = useSelector(state => state.examTypeState)

    const ans = JSON.parse(localStorage.getItem('answer'))

    function handleClick(e) {
        let category = (examdata.exam[examIndex].data[e].category) ? examdata.exam[examIndex].data[e].category : ""
        dispatch(jump_questions(e))
        dispatch(processing_jump(e))
        dispatch(set_exam_step_category(category))
    }

    return <Grid container align="left" style={{margin:'5px auto',padding:10}} dir={(examType === params.examType.TOAFL) ? "rtl" : "ltr"}>
        {(examIndex === params.examIndex.READING) ? props.questions.map((_, index) => (
            <Grid key={index} item>
                {
                    (ans != null && typeof ans !== "undefined" && ans[index].some(function(i) { return i !== null; })) ?
                        <button className={`${index === questionIndex ? classes.buttonActive : classes.buttonAnswer} ${classes.buttonNumber}`} onClick={() => handleClick(index)}>{(examType === params.examType.TOAFL) ? numberToArabic(index+1) : index+1}</button>
                    :
                        <button className={`${index === questionIndex && classes.buttonActive} ${classes.buttonNumber}`} onClick={() => handleClick(index)}>{(examType === params.examType.TOAFL) ? numberToArabic(index+1) : index+1}</button>
                }
            </Grid>
        )) : props.questions.map((_, index) => (
            <Grid key={index} item>
                {
                    (ans != null && typeof ans !== "undefined" && typeof ans[index] !== "undefined" && ans[index] != null) ?
                        <button 
                            className={`${index === questionIndex ? 
                                classes.buttonActive 
                                : classes.buttonAnswer} ${classes.buttonNumber}`} 
                            onClick={() => handleClick(index)}>
                                {(examType === params.examType.TOAFL) ? numberToArabic(index+1) : index+1}
                        </button>
                    :
                        <button className={`${index === questionIndex && classes.buttonActive} ${classes.buttonNumber}`} onClick={() => handleClick(index)}>{(examType === params.examType.TOAFL) ? numberToArabic(index+1) : index+1}</button>
                }
            </Grid>
        ))}
    </Grid>;
}

export default MiniMapno